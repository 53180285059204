<template>
  <div class="nes-container primary-bg">
    <div
      class="grid grid-cols-1 md:grid-cols-1 gap-6 items-center justify-between text-left overflow-hidden"
    >
      <div class="flex justify-center md:hidden">
        <button
          v-if="!state.userAddress"
          id="show-modal"
          class="text-black text-base md:text-xl uppercase py-1 px-3 bg-white border-2 border-black is-primary"
          @click="showTezosModal"
        >
          Don't have a Tezos wallet?
        </button>
        <HelpModal v-show="isModalVisible" @close="closeTezosModal" />
      </div>

      <div class="nes-container container-padding bg-white">
        <div class="grid grid-cols-1 md:grid-cols-3">
          <span class="medal-gold" />
          <div class="text-center col-span-12">
            <img
              alt="Text Top Layer"
              src="../assets/text-top-layer.png"
              class="mx-auto pt-4 sm:pt-0 pb-2"
            />
            <span class="title"> Bounty Hunters </span>
            <img
              alt="Text Top Layer"
              src="../assets/text-bottom-layer.png"
              class="mx-auto pt-2 pb-6 md:pb-0"
            />
          </div>

          <div class="text-center col-span-12">
            <p class="subtitle md:ml-4 md:text-center mb-2 p-5">
              Get freebies and airdrops for completing simple tasks.
            </p>
          </div>
        </div>

        <div class="nes-container landing-container box-text secondary-bg text-center mb-4 md:md-0">
          Follow the steps below to be eligible for a free pack of 15 PixelPower weapons!
        </div>
        <Borders />
      </div>

      <div class="nes-container container-padding bg-alt">
        <div class="grid grid-cols-1 mt-4">
          <ol v-if="!submitted" class="list-decimal pl-10 mb-0">
            <li class="text-base md:text-2xl">
              Connect your Tezos (XTZ) wallet.
              <span v-if="state.userAddress" class="text-base md:text-2xl"
                >(Connected to
                <AddrIcon
                  :address="state.userAddress"
                  :alias="state.userAlias"
                  :show-disconnect="true"
                  :is-pill="false"
                  :link-to-collection="false"
                />)</span
              ><span v-else>
                <button
                  class="text-white text-sm lg:text-xl uppercase py-1 px-1 md:py-1 md:px-3 bg-white border-2 border-black is-primary bg-button"
                  @click="showWalletModal"
                >
                  Connect Wallet
                </button>
                <WalletModal
                  v-show="isWalletModalVisible && !state.userAddress"
                  :connect-wallet="connectWallet"
                  @close="closeWalletModal"
                />
              </span>
            </li>
            <li v-if="state.userAddress" class="text-base md:text-2xl">
              Follow
              <a href="https://twitter.com/pixelpotus" target="_blank" class="text-base md:text-2xl"
                >@PixelPotus on Twitter <fa :icon="['fab', 'twitter']" class="align-baseline"
              /></a>
            </li>
            <li v-if="state.userAddress" class="text-base md:text-2xl">
              <ShareNetwork
                network="twitter"
                :url="'https://www.pixelpower.xyz/bounty'"
                :title="`Get 15 FREE weapon NFTs in the PixelPower airdrop! 
PixelPower is a high-caliber NFT competition. Players compete to collect 50+ NFT weapons with 6 levels of rarity, build their firepower and earn $PXL.`"
                :description="`Get 15 FREE weapon NFTs in the PixelPower airdrop! 
PixelPower is a high-caliber NFT competition. Players compete to collect 50+ NFT weapons with 6 levels of rarity, build their firepower and earn $PXL.`"
                twitter-user="pixelpotus"
                hashtags="nft,p2e,tezos,pixelpower"
                class="text-base md:text-2xl"
              >
                Click here to share
              </ShareNetwork>
              about this bounty to your twitter followers. Copy the link to your tweet for Step 5!
            </li>
            <li v-if="state.userAddress" class="text-base md:text-2xl">
              Join the
              <a href="https://discord.gg/CbdbvwtwkM" target="_blank" class="text-base md:text-2xl"
                >Pixel Games discord community
                <fa :icon="['fab', 'discord']" class="align-baseline"
              /></a>
            </li>
            <li v-if="state.userAddress" class="text-base md:text-2xl">
              Paste the link to your tweet from Step 3 in the #bounty-hunters channel.
            </li>
            <li v-if="state.userAddress" class="text-base md:text-2xl">
              Complete this captcha to submit your wallet for the airdrop.
              <div class="flex-none align-middle">
                <vue-recaptcha
                  ref="vueRecaptchaBounty"
                  class="m-auto flex-grow"
                  :sitekey="siteKey"
                  size="normal"
                  theme="light"
                  :tabindex="0"
                  @verify="recaptchaVerified"
                  @expire="recaptchaExpired"
                  @fail="recaptchaFailed"
                />
              </div>
            </li>
          </ol>
          <div
            v-else
            class="nes-container landing-container box-text secondary-bg text-center mb-4 md:md-0"
          >
            Success! Our team will validate your submission and add you to the list. Airdrops are
            processed on a weekly basis. Please stay tuned to our discord for updates.
          </div>
        </div>
        <Borders />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vueRecaptcha from 'vue3-recaptcha2'

import AddrIcon from '../components/AddrIcon.vue'
import Borders from '../components/Borders'
import HelpModal from '../components/HelpModal.vue'
import WalletModal from '../components/WalletModal.vue'

export default {
  name: 'Bounty',
  components: {
    Borders,
    HelpModal,
    AddrIcon,
    WalletModal,
    vueRecaptcha,
  },
  props: {
    connectWallet: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      state: this.$root.$data.state,
      isModalVisible: false,
      isWalletModalVisible: false,
      submitted: false,
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    }
  },
  computed: {
    formattedAddr() {
      return (
        this.state.contract.substring(0, 5) +
        '..' +
        this.state.contract.substring(this.state.contract.length - 5)
      )
    },
  },
  mounted() {
    if (this.$route.query?.ref && this.$route.query?.ref.length === 36) {
      localStorage.setItem('powerReferrer', this.$route.query?.ref)
    }
  },
  methods: {
    showTezosModal() {
      this.isModalVisible = true
    },
    closeTezosModal() {
      this.isModalVisible = false
    },
    showWalletModal() {
      this.isWalletModalVisible = true
    },
    closeWalletModal() {
      this.isWalletModalVisible = false
    },
    async recaptchaVerified(captchaResp) {
      try {
        await axios({
          url: '/api/submitWallet',
          method: 'POST',
          data: {
            address: this.state.userAddress,
            captcha: captchaResp,
          },
        })
        this.submitted = true
      } catch (e) {
        alert('Error submitting wallet. Please try again.')
      }
    },
    recaptchaExpired() {
      this.$refs.vueRecaptchaBounty.reset()
    },
    recaptchaFailed() {
      this.state.log('captcha failed')
    },
  },
}
</script>
<style scoped>
@screen sm {
  .container-padding {
    padding: 2rem;
  }
}

@screen md {
  .container-padding {
    padding: 2rem 2rem;
  }
  .title {
    font-size: 56px !important;
    line-height: 56px !important;
  }
  .subtitle {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .text {
    font-size: 24px !important;
    line-height: 40px !important;
  }

  .box-text {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

@screen lg {
  .container-padding {
    padding: 2rem 4rem;
  }
}

.title {
  font-size: 28px;
  line-height: 28px;
}

.subtitle {
  font-size: 24px;
  line-height: 36px;
}

.text {
  font-size: 20px;
  line-height: 36px;
}

.box-text {
  font-size: 18px;
  line-height: 22px;
}

.landing-container {
  padding: 1.25rem !important;
}

secondary-bg {
  background: url('../assets/rectangle-gradient.png') no-repeat;
  background-size: cover;
}
.help-link {
  color: #fff;
  background: #f59e0b;
}

.official-link {
  background: #00aeff;
  color: #fff;
  border: 2px solid #000;
  padding: 0.8rem;
}

.refer-link {
  background: #00aeff;
  color: #fff;
  border: 2px solid #000;
  padding: 0.8rem;
}

.logo-height {
  height: 120px;
}
</style>
