import './index.css'
import './nes.min.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'primevue/resources/themes/tailwind-light/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css'
import './styles.css'
import 'vue3-snackbar/styles'

import PrimeVue from 'primevue/config'
import Timeline from 'primevue/timeline'
import { createApp } from 'vue'
import VueSocialSharing from 'vue-social-sharing'
import VueClickAway from 'vue3-click-away'
import VueClipboard from 'vue3-clipboard'
import { SnackbarService, Vue3Snackbar } from 'vue3-snackbar'

import { FontAwesomeIcon } from '@/plugins/font-awesome'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app
  .use(router)
  .use(PrimeVue)
  .use(VueClickAway)
  .use(VueSocialSharing)
  .use(SnackbarService)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .component('vue3-snackbar', Vue3Snackbar)
  .component('fa', FontAwesomeIcon)
  .component('Timeline', Timeline)
  .mount('#app')
