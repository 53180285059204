<template>
  <div class="nes-container primary-bg overflow-hidden">
    <div
      class="nes-container bg-white grid grid-cols-1 md:grid-cols-1 gap-0 items-center justify-between text-left"
    >
      <span class="medal-gold" />
      <h1 class="text-xl text-center pt-6">
        <AddrIcon :address="owner" :alias="alias" />'s Arsenal
      </h1>

      <div v-if="loading || !tokensLoaded" class="text-center">Loading...</div>
      <div v-if="tokensLoaded && tokens.length">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          <div
            v-for="(tok, tkey) in tokens.slice(offset, offset + limit)"
            :key="tkey"
            class="text-left cursor-pointer"
            @click="$router.push('/token/' + tok.tokenId)"
          >
            <div class="flex-shrink-0 w-50 pt-3">
              <img
                class="w-full squared-full"
                :class="{ 'vibrate-1': tok.upgrading }"
                :src="tok.metadata.displayUri"
                alt=""
              />
              <div class="-mt-6 text-left ml-1">
                <span class="bg-white rounded p-1 opacity-90">x{{ tok.balance }}</span>
              </div>
              <div class="-mt-6 text-right mr-1">
                <span class="bg-white rounded p-1 opacity-90">{{ tok.tokenId }}</span>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          :offset="offset"
          :limit="limit"
          :pages="pages"
          @first-page="firstPage"
          @prev-page="prevPage"
          @next-page="nextPage"
          @last-page="lastPage"
          @page-size-selected="pageSizeSelected"
        >
          <template #perPageOptions>
            <option value="4">4</option>
            <option value="12">12</option>
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
          </template>
        </Pagination>
      </div>
      <Borders />
    </div>
    <div class="nes-container container-padding mt-10 bg-white">
      <div class="grid grid-cols-3 md:grid-cols-3">
        <div class="text-center col-span-12 lg:col-span-1">
          <img alt="Text Top Layer" src="../assets/text-top-layer.png" class="mx-auto pb-6 pt-6" />
          <span class="font-black text-4xl"
            >Level <br />
            Up!</span
          >
          <img
            alt="Text Top Layer"
            src="../assets/text-bottom-layer.png"
            class="mx-auto pb-6 pt-6"
          />
        </div>

        <div class="col-span-12 lg:col-span-2 text-left pl-10 text-sm">
          <p class="text-xl">Collect And Upgrade</p>

          <ul class="list-disc lg:px-10 leading-loose">
            <li class="text-lg">
              Collect and burn two commons
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[0].req_pxl, 0) }} $PXL</span
              >
              to mint one uncommon
            </li>
            <li class="text-lg">
              Collect and burn three uncommons
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[1].req_pxl, 0) }} $PXL</span
              >
              to mint one rare
            </li>
            <li class="text-lg">
              Collect and burn four rares
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[2].req_pxl, 0) }} $PXL</span
              >
              to mint one epic
            </li>
            <li class="text-lg">
              Collect and burn five epics
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[3].req_pxl, 0) }} $PXL</span
              >
              to mint one legendary
            </li>
            <li class="text-lg">
              Collect and burn 10 legendaries
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[4].req_pxl, 0) }} $PXL</span
              >
              to mint one unique
            </li>
          </ul>
        </div>
      </div>

      <img alt="cards" src="../assets/cards.png" class="mx-auto mb-6 mt-6 hidden md:block" />

      <img alt="cards" src="../assets/cards-mobile.png" class="mx-auto mb-6 mt-6 block md:hidden" />

      <div class="nes-container secondary-bg mt-10 text-center mb-10 text-3xl">
        Your total FirePower is the combined FirePower of all the weapons you own.
      </div>

      <div class="grid grid-cols-3 md:grid-cols-3 pb-6 content-center items-center">
        <div class="text-center col-span-12 lg:col-span-1">
          <img alt="Text Top Layer" src="../assets/text-top-layer.png" class="mx-auto pb-6 pt-6" />
          <span class="font-black text-4xl"
            >The <br />
            Strongest <br />
            Are <br />
            Rewarded</span
          >
          <img
            alt="Text Top Layer"
            src="../assets/text-bottom-layer.png"
            class="mx-auto pb-6 pt-6"
          />
        </div>

        <div class="col-span-12 lg:col-span-2 text-left text-lg pl-10 align-middle">
          <p class="text-2xl">
            50% of all PixelPower claim & upgrade fees are shared back with the community each
            season:
          </p>
          <div
            v-if="!loading && state.season.end"
            class="flex align-center justify-center text-2xl"
          >
            <Countdown
              :prefix="'Season ends in '"
              :completed-msg="'Season ended! Rewards calculation is in progress...'"
              :timestamp="state.season.end"
              :countdown-classes="'text-2xl text-black'"
            />
          </div>
          <p class="text-2xl">
            🔫 20% is split among the top 10% of collectors with the highest all-time FirePower.
          </p>
          <p class="mt-10 text-2xl">
            🔫 20% is split among the top 10% of collectors with the most FirePower gathered during
            the current season.
          </p>
          <p class="mt-10 text-2xl">🔫 10% is shared back to those who refer their friends.</p>
        </div>
      </div>

      <img alt="cards fade" src="../assets/cards-fade.png" class="mx-auto pb-6 pt-6" />

      <Borders />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { find } from 'lodash'

import AddrIcon from '../components/AddrIcon'
import Borders from '../components/Borders'
import Countdown from '../components/Countdown.vue'
import Pagination from '../components/Pagination'

export default {
  name: 'ViewCollection',
  components: {
    AddrIcon,
    Borders,
    Pagination,
    Countdown,
  },
  data() {
    return {
      state: this.$root.$data.state,
      loading: false,
      tokens: [],
      tokensLoaded: false,
      sortBy: 'tokenId',
      sortDir: true,
      offset: 0,
      limit: 4,
      owner: '',
      alias: '',
      trades: [],
      totalTrades: 0,
      tradeLimit: 4,
      tradeOffset: 0,
      auctions: [],
      totalAuctions: 0,
      auctionLimit: 3,
      auctionOffset: 0,
    }
  },
  computed: {
    pages() {
      if (!this.tokens) {
        return 1
      }
      let tot = parseInt((this.tokens.length / this.limit).toFixed(0))
      if (tot < this.tokens.length / this.limit) {
        tot++
      }
      return tot
    },
    tradePages() {
      if (!this.trades) {
        return 1
      }
      let tot = parseInt((this.totalTrades / this.tradeLimit).toFixed(0))
      if (tot < this.totalTrades / this.tradeLimit) {
        tot++
      }
      return tot
    },
  },
  async mounted() {
    this.state.log('mounted ViewCollection', this.state.tokens, this.state.tokensLoaded)
    this.getId()
  },
  methods: {
    async pageSizeSelected(value) {
      this.limit = parseInt(value)
      this.offset = 0
    },
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.tokens.length) {
        this.offset -= this.limit
      }
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {
        this.offset = 0
      } else {
        this.offset = offset
      }
    },
    firstPage() {
      this.offset = 0
    },
    lastPage() {
      this.offset = (this.pages - 1) * this.limit
    },
    async pageSizeSelectedTrades(value) {
      this.tradeLimit = parseInt(value)
      this.tradeOffset = 0
      this.getTrades()
    },
    nextPageTrades() {
      this.tradeOffset += this.tradeLimit
      if (this.tradeOffset >= this.tokens.length) {
        this.tradeOffset -= this.tradeLimit
      }
      this.getTrades()
    },
    prevPageTrades() {
      let offset = this.tradeOffset - this.tradeLimit
      if (offset < 0) {
        this.tradeOffset = 0
      } else {
        this.tradeOffset = offset
      }
      this.getTrades()
    },
    firstPageTrades() {
      this.tradeOffset = 0
      this.getTrades()
    },
    lastPageTrades() {
      this.tradeOffset = (this.tradePages - 1) * this.tradeLimit
      this.getTrades()
    },
    async pageSizeSelectedAuctions(value) {
      this.auctionLimit = parseInt(value)
      this.auctionOffset = 0
      this.getAuctions()
    },
    nextPageAuctions() {
      this.auctionOffset += this.auctionLimit
      if (this.auctionOffset >= this.tokens.length) {
        this.auctionOffset -= this.auctionLimit
      }
      this.getAuctions()
    },
    prevPageAuctions() {
      let offset = this.auctionOffset - this.auctionLimit
      if (offset < 0) {
        this.auctionOffset = 0
      } else {
        this.auctionOffset = offset
      }
      this.getAuctions()
    },
    firstPageAuctions() {
      this.auctionOffset = 0
      this.getAuctions()
    },
    lastPageAuctions() {
      this.auctionOffset = (this.auctionPages - 1) * this.auctionLimit
      this.getAuctions()
    },
    async getId() {
      try {
        if (this.$route.params.owner) {
          if (this.state.aliases.length === 0) {
            await new Promise((resolve) => setTimeout(resolve, 500))
            return this.getId()
          }
          this.owner = this.$route.params.owner
          const alias = find(this.state.aliases, (a) => a.address === this.owner)
          if (alias) this.alias = alias.alias
          this.loading = true
          const tokResp = await axios({
            method: 'post',
            url: '/api/myTokens',
            data: {
              allTokens: this.state.allTokens,
              userAddress: this.$route.params.owner,
            },
          })

          tokResp.data.tokens.forEach((tok) => {
            this.tokens.push({ ...tok, upgrading: false })
          })

          this.sortTokens('rarity', false)
          this.tokensLoaded = true

          // await this.getTrades()
          // await this.getAuctions()

          this.loading = false
        } else {
          this.tokens.length = 0
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    async getTrades() {
      const resp = await axios({
        url: '/api/trades',
        params: {
          limit: this.tradeLimit,
          offset: this.tradeOffset,
          owner: this.$route.params.owner,
        },
      })
      this.trades = resp.data.trades.map((trade) => {
        if (this.alias) {
          trade.alias = this.alias
        }
        return trade
      })
      this.totalTrades = resp.data.total
    },
    async getAuctions() {
      const resp = await axios({
        url: '/api/auctions',
        params: {
          limit: this.auctionLimit,
          offset: this.auctionOffset,
          seller: this.$route.params.owner,
        },
      })
      this.auctions = resp.data.auctions.map((auction) => {
        if (this.alias) {
          auction.sellerAlias = this.alias
        }
        return auction
      })
      this.totalAuctions = resp.data.total
    },
    sortTokens(sortBy, dir) {
      this.sortDir = dir
      this.sortBy = sortBy

      this.tokens.sort((a, b) => {
        switch (sortBy) {
          case 'name':
            if (this.sortDir) return ('' + a.metadata.name).localeCompare(b.metadata.name)
            return ('' + b.metadata.name).localeCompare(a.metadata.name)
          case 'rarity':
            if (this.sortDir) return a.tokenId - b.tokenId
            return b.tokenId - a.tokenId
          case 'balance':
            if (this.sortDir) return a.balance - b.balance
            return b.balance - a.balance
          default:
            if (this.sortDir) return (a.tokenId % 1000) - (b.tokenId % 1000)
            return (b.tokenId % 1000) - (a.tokenId % 1000)
        }
      })
    },
  },
}
</script>
