<template>
  <div
    class="inline"
    :class="{ 'rounded-full py-2 px-3 bg-black text-white': isPill, 'text-black': !isPill }"
  >
    <span v-if="linkToCollection">
      <router-link v-if="alias && alias !== ''" class="golden-alias" :to="'/collection/' + address">
        {{ alias }}
      </router-link>
      <router-link v-else :to="'/collection/' + address">
        <span :class="{ ' text-white': isPill, 'text-black': !isPill }">{{
          'ꜩ' + formattedAddr
        }}</span>
      </router-link>
    </span>
    <span v-else>
      <span v-if="alias && alias !== ''" class="golden-alias">{{ alias }}</span>
      <span v-else>{{ 'ꜩ' + formattedAddr }}</span>
    </span>
    <button
      v-if="showDisconnect"
      class="text-lg hover:text-blue-600 cursor-pointer ml-1"
      title="Disconnect Wallet"
      @click="disconnect"
    >
      <fa icon="times-circle" class="align-middle" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'AddrIcon',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    address: String,
    alias: {
      type: String,
      default: '',
    },
    showDisconnect: {
      type: Boolean,
      default: false,
    },
    isPill: {
      type: Boolean,
      default: false,
    },
    linkToCollection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      state: this.$root.$data.state,
    }
  },
  computed: {
    formattedAddr() {
      return this.address.substring(0, 5) + '..' + this.address.substring(this.address.length - 5)
    },
  },
  methods: {
    async disconnect() {
      try {
        await this.state.disconnect()
        this.$router.push('/')
      } catch (e) {
        console.error('Unable to disconnect wallet', e)
      }
    },
  },
}
</script>
