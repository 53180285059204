<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="nes-container modal-padding bg-white">
        <div class="wallet-modal">
          <header class="modal-header">
            <button type="button" class="btn-close text-black" @click="close">X Close</button>
          </header>

          <section class="modal-body">
            <slot name="body">
              <img alt="Pixel Power" src="../assets/badge.png" class="mx-auto pb-2" width="69" />
              <p class="text-center text-2xl">Connect Your Tezos Wallet</p>
              <div v-if="!state.userAddress" class="flex flex-col items-center justify-center">
                <div
                  v-if="!state.geoLoaded"
                  class="flex flex-row items-center justify-center text-black border-2 border-black border-dashed p-2"
                >
                  <div class="text-lg text-gray-800">Loading...</div>
                </div>
                <div
                  v-else-if="!state.canParticipate"
                  class="flex flex-row items-center justify-center text-black border-2 border-black border-dashed p-2"
                >
                  <div class="text-lg text-gray-800">
                    Unfortunately, users from your jurisdiction are not allowed to participate.
                  </div>
                </div>
                <div
                  v-else-if="!state.agreedToDisclaimer"
                  class="flex flex-row items-center justify-center text-black border-2 border-black border-dashed p-2"
                >
                  <span class="p-5">
                    <input
                      type="checkbox"
                      @change="
                        () => {
                          state.saveDisclaimer()
                        }
                      "
                    />
                  </span>
                  <div class="text-lg text-gray-800">
                    In order to connect your wallet, please check this box to indicate you have read
                    and agree to our
                    <a href="/terms" target="_blank" class="text-blue-dark">
                      terms & conditions.
                    </a>
                  </div>
                </div>
                <div class="mt-5 flex flex-col items-center text-center justify-center">
                  <button
                    class="self-center text-sm uppercase py-4 px-6 text-white bg-blue-dark hover:bg-blue-light border-2 border-gray-700 nes-btn is-primary h-20"
                    :class="{ 'is-disabled': !state.agreedToDisclaimer }"
                    :disabled="!state.agreedToDisclaimer"
                    @click="connectWallet"
                  >
                    Connect Wallet
                  </button>
                  <a href="/terms" target="_blank" class="text-blue-dark hov:text-blue-light">
                    Terms & Conditions
                  </a>
                </div>
              </div>
            </slot>
          </section>
          <Borders />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Borders from '../components/Borders'

export default {
  name: 'HelpModal',
  components: {
    Borders,
  },
  props: {
    connectWallet: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  emits: ['close'],
  data() {
    return {
      state: this.$root.$data.state,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wallet-modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 95vw;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  justify-content: space-between;
}

.modal-footer {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.modal-body {
  position: relative;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 35px;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.modal-padding {
  padding: 0.5rem;
}
</style>
