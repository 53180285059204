<template>
  <div class="nes-container primary-bg overflow-hidden">
    <div
      class="nes-container bg-white grid grid-cols-1 md:grid-cols-1 gap-0 items-center justify-between text-left"
    >
      <span class="medal-gold" />
      <div class="mx-auto mb-5 mt-8">
        <img alt="Lightning" src="../assets/badge.png" width="69" />
      </div>
      <h1 class="text-2xl text-center">
        My Arsenal<button
          class="text-blue-link hover:text-blue-hov"
          @click="
            () => {
              state.history.isOpen = true
            }
          "
        >
          <fa icon="history" class="h-5 w-5 align-baseline" />
        </button>
        <br />
        <span class="text-xs"
          >Last refresh: {{ myTokensTimestamp ? myTokensTimestamp.fromNow() : 'never' }}
          <button
            v-if="myTokensTimestamp"
            class="text-blue-link hover:text-blue-hov"
            alt="Refresh Tokens"
            :disabled="state.myTokensLoading"
            @click="state.loadMyTokens(true, true)"
          >
            <fa
              icon="sync"
              :class="{ 'animate-spin': state.myTokensLoading }"
              class="h-5 w-5 align-bottom"
            /></button
        ></span>
      </h1>
      <div v-if="state.loading || !state.myTokensLoaded" class="text-center">Loading...</div>
      <div class="rounded-full mb-2 py-2 px-3 bg-black text-white m-auto my-2">
        <div>
          Alias: {{ state.userAlias || 'none' }}
          <button
            class="text-blue-link hover:text-blue-hov"
            @click="
              () => {
                showAliasInput = !showAliasInput
                aliasInput = state.userAlias
              }
            "
          >
            <fa icon="edit" class="h-5 w-5 align-baseline" />
          </button>
        </div>
      </div>
      <div v-if="showAliasInput" class="m-auto">
        <div class="nes-field mb-2 grid grid-cols-3">
          <label for="alias_field" class="col-span-3 text-xs"
            >Set an alias up to 15 chars long for
            {{ state.convertFromMutez(aliaserCost) }} XTZ.<br />It will be displayed everywhere in
            place of<br />
            your address. Please keep it PG :)</label
          >
          <input
            id="alias_field"
            v-model="aliasInput"
            type="text"
            class="nes-input col-span-2"
            :maxlength="aliaserMaxLen"
          />
          <button class="nes-btn is-primary col-span-1" :disabled="settingAlias" @click="setAlias">
            <fa v-if="settingAlias" icon="cog" class="animate-spin h-5 w-5 align-baseline" />
            Set<span v-if="settingAlias">ting</span> Alias
          </button>
        </div>
      </div>

      <div
        v-if="state.userAddress && rewardsStatus.bal > 0"
        class="grid grid-cols-8 items-center nes-container secondary-bg mt-5 mb-5 md:mb-0 text-left"
      >
        <div class="col-span-1 w-14 h-14 mr-3">
          <span>
            <img alt="Lightning" src="../assets/lightning.png" />
          </span>
        </div>
        <div class="col-span-7 md:col-span-5">
          <span class="text-base md:text-2xl text-center md:text-left">
            Congratulations! You have {{ state.convertFromMutez(rewardsStatus.bal, 0) }} unclaimed
            $PXL rewards from previous seasons.
          </span>
        </div>
        <div class="col-span-8 md:col-span-2 justify-self-center md:justify-self-end w-14 h-14">
          <button
            class="py-4 px-6 border-2 border-gray-700 bg-blue-normal text-white"
            @click="claimRewardsBalance"
          >
            Claim Now
          </button>
        </div>
      </div>

      <div
        v-if="myRank !== -1"
        class="w-full m-auto mb-5 md:mb-0 text-center nes-container secondary-bg"
      >
        <h1 class="text-2xl">Your Rank: #{{ myRank }} of {{ total }}</h1>
        {{ percentile }}
      </div>

      <div v-if="state.myTokensLoaded" class="flex flex-col border-t border-b">
        <div
          class="flex flex-col items-center justify-center p-5 bg-yellow-500 text-black nes-container"
        >
          <h2 class="text-2xl">
            Refer & Earn
            <button class="" @click="toggleShowRefer()">
              <fa v-if="!showRefer" icon="chevron-right" class="text-black h-5 w-5 ml-1" />
              <fa v-if="showRefer" icon="chevron-down" class="text-black h-5 w-5 ml-1" />
            </button>
          </h2>
          <span v-show="showRefer">
            <div
              class="flex flex-col items-center justify-center border-black border-2 border-dashed p-3"
            >
              Refer your friends and earn 10% of their claim and upgrade fees. Thanks for helping us
              spread the love!
            </div>
            <div
              v-if="refStatus.isLoaded"
              class="flex flex-col items-center justify-center border-black border-2 border-dashed p-3"
            >
              <div v-if="refStatus.referredBy === false" class="col-span-1">
                <button
                  class="nes-btn is-primary"
                  :class="{ 'is-disabled': refLoading }"
                  :disabled="refLoading"
                  @click="genRefLink"
                >
                  <fa v-if="refLoading" icon="cog" class="animate-spin h-5 w-5 align-baseline" />
                  Generate Referral Link
                </button>
              </div>
              <div v-else class="col-span-1">
                <h3 class="text-left mb-4">Your Referral Link:</h3>
                <div class="grid grid-cols-12" style="">
                  <input type="text" disabled :value="shareUrl" class="nes-input col-span-11" />
                  <button class="nes-btn is-primary align-center col-span-1" @click="copyShareUrl">
                    <fa icon="copy" class="align-baseline" />
                  </button>
                </div>
                <p class="flex flex-row gap-2">
                  <ShareNetwork
                    network="facebook"
                    :url="shareUrl"
                    :title="`My firepower is bigger than yours ;P - PixelPower is a high-caliber NFT competition built on the tezos blockchain. Players compete to collect 50+ NFT weapons with 6 levels of rarity, build their firepower and earn rewards.`"
                    :description="`My firepower is bigger than yours ;P - PixelPower is a high-caliber NFT competition built on the tezos blockchain. Players compete to collect 50+ NFT weapons with 6 levels of rarity, build their firepower and earn rewards.`"
                    hashtags="nft,p2e,tezos"
                  >
                    <fa :icon="['fab', 'facebook']" class="align-baseline" />
                  </ShareNetwork>
                  <ShareNetwork
                    network="twitter"
                    :url="shareUrl"
                    :title="`My firepower is bigger than yours ;P - PixelPower is a high-caliber NFT competition built on the tezos blockchain. Players compete to collect 50+ NFT weapons with 6 levels of rarity, build their firepower and earn rewards.`"
                    :description="`My firepower is bigger than yours ;P - PixelPower is a high-caliber NFT competition built on the tezos blockchain. Players compete to collect 50+ NFT weapons with 6 levels of rarity, build their firepower and earn rewards.`"
                    twitter-user="pixelpotus"
                    hashtags="nft,p2e,tezos"
                  >
                    <fa :icon="['fab', 'twitter']" class="align-baseline" />
                  </ShareNetwork>
                  <ShareNetwork
                    network="reddit"
                    :url="shareUrl"
                    :title="`My firepower is bigger than yours ;P - PixelPower is a high-caliber NFT competition built on the tezos blockchain. Players compete to collect 50+ NFT weapons with 6 levels of rarity, build their firepower and earn rewards.`"
                  >
                    <fa :icon="['fab', 'reddit']" class="align-baseline" />
                  </ShareNetwork>
                </p>
                <hr class="mt-4" />
                <h3 class="text-left mb-4 mt-4">
                  Your Referral Rewards Balance:
                  {{ (refStatus.bal / 1000000).toFixed(0) }} $PXL<button
                    class="nes-btn is-primary"
                    :class="{ 'is-disabled': refLoading }"
                    :disabled="refLoading"
                    @click="claimRefBalance"
                  >
                    <fa v-if="refLoading" icon="cog" class="animate-spin h-5 w-5 align-baseline" />
                    Claim
                  </button>
                </h3>
              </div>
            </div>
          </span>
        </div>
        <div class="p-5 nes-container bg-blue-lightest">
          <div class="flex flex-col items-center justify-center">
            <h2 class="text-2xl">Filters</h2>
          </div>
          <div class="flex flex-col sm:flex-row">
            <label for="filter_trades" class="text-lg font-medium text-black">Search By: </label>
            <input
              id="filter_trades"
              v-model="filter"
              type="text"
              name="filter_trades"
              class="lg:w-full p-2 border-2 ml-10 focus:ring-blue-500 focus:border-blue-500 shadow-sm border-gray-300 rounded-none md:w-3/5 xl:w-1/3"
              placeholder="Weapon Name"
              @input="updateFilters()"
            />
          </div>
          <div class="flex flex-col sm:flex-row">
            <label class="text-lg font-medium text-black mr-3">Rarities:</label>
            <div
              class="relative inline-block my-2 mr-2 ml-10 align-middle select-none transition duration-200 ease-in shadow-sm border-gray-300 rounded-none lg:w-full md:w-3/5 xl:w-1/3"
            >
              <VueMultiselect
                v-model="rarities"
                :options="raritiesOptions"
                :multiple="true"
                :show-labels="false"
                placeholder="Select Rarities"
                @select="updateFilters"
                @remove="updateFilters"
              />
            </div>
          </div>
          <div class="flex flex-row items-end justify-start">
            <label for="toggleShowUpgradable" class="text-lg font-medium text-black mr-3"
              >Upgradable:</label
            >
            <div
              class="relative inline-block w-10 mt-2 mr-2 ml-2 align-middle select-none transition duration-200 ease-in"
            >
              <input
                id="toggleShowUpgradable"
                v-model="batchSelect"
                type="checkbox"
                name="toggleShowUpgradable"
                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                @input="toggleBatchSelect"
              />
              <label
                for="toggleShowUpgradable"
                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div
          v-if="visibleTokens.length"
          class="hidden lg:inline -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
        >
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead v-if="!upgrading" class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <a href="#" @click="sortTokens('name')">Name</a>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <a href="#" @click="sortTokens('balance')">Balance</a>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <a href="#" @click="sortTokens('rarity')">Rarity</a>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Upgrade Reqs
                    </th>
                    <th scope="col col-2 gap-2" class="relative px-6 py-3">
                      <button
                        v-if="!batchSelect"
                        class="text-white text-xs border-2 border-gray-700 bg-blue-normal py-2 px-2"
                        @click="toggleBatchSelect(true)"
                      >
                        START BATCH UPGRADE
                      </button>
                      <button
                        v-if="batchSelect && !upgrading"
                        class="text-xs border-2 border-gray-700 bg-blue-normal mx-2 py-2 px-2"
                        @click="upgrade(batchTokens)"
                      >
                        Upgrade {{ batchTokens.length }}
                      </button>
                      <button
                        v-if="batchSelect && !upgrading"
                        class="text-xs border-2 border-gray-700 py-2 px-2"
                        @click="handleBatchStop()"
                      >
                        Cancel
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(tok, tkey) in visibleTokens.slice(offset, offset + limit)"
                    v-show="
                      (!upgrading || tok.upgrading) &&
                      (!batchSelect || (batchSelect && tok.balance >= tok.upgradeReq))
                    "
                    :key="tkey"
                  >
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div
                          class="flex-shrink-0"
                          :class="{ 'w-1/3': tok.upgrading, 'w-10': !tok.upgrading }"
                        >
                          <img
                            class="squared-full"
                            :class="{ 'vibrate-1 w-full': tok.upgrading, 'w-10': !tok.upgrading }"
                            :src="tok.metadata.displayUri"
                            alt=""
                          />
                        </div>
                        <div v-if="!tok.upgrading" class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            <router-link
                              class="text-base font-medium"
                              :to="`/token/${tok.tokenId}`"
                            >
                              {{ tok.metadata.shortName }}
                            </router-link>
                          </div>
                          <div class="text-sm text-gray-500">Token ID: {{ tok.tokenId }}</div>
                          <!-- <div class="text-sm text-gray-500">
                            Last: {{ state.convertFromMutez(tok.last, 0) }}$PXL
                          </div> -->
                        </div>
                      </div>
                    </td>
                    <td v-if="!tok.upgrading" class="px-6 py-4 whitespace-nowrap">
                      <span
                        class="px-2 inline-flex text-xs leading-5 squared-full bg-green-100 text-green-800"
                      >
                        {{ tok.balance }}
                      </span>
                    </td>
                    <td
                      v-if="!tok.upgrading"
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ tok.rarity }}
                    </td>
                    <td v-if="!tok.upgrading" class="px-6 py-4 whitespace-nowrap">
                      <div v-if="tok.rarity === 'unique'" class="text-sm">Maxed</div>
                      <span v-else>
                        <div class="text-sm text-gray-900">
                          <img width="10" class="inline mr-2 mb-2" src="../assets/flame.png" />{{
                            tok.upgradeReq
                          }}
                          {{ tok.rarity }}s
                        </div>
                        <div class="text-sm squared-full text-green-800">
                          <fa icon="coins" class="h-5 w-5 mr-1" />{{
                            state.convertFromMutez(tok.upgradeCost, 0)
                          }}<span class="text-gray-500"> $PXL</span>
                        </div>
                        <span class="text-xs text-gray-500">=1x {{ tok.upgradeTo }}</span>
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <div v-if="tok.rarity !== 'unique' && !batchSelect">
                        <button
                          :disabled="tok.balance < tok.upgradeReq || tok.upgrading"
                          class="text-blue-link hover:text-blue-hov block"
                          @click="upgrade(tok)"
                        >
                          <span v-if="tok.upgrading">
                            <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
                            Upgrading... Please wait up to 1 min for block confirmation.
                          </span>
                          <span v-else>
                            <span
                              v-if="tok.balance < tok.upgradeReq || state.loading"
                              class="line-through text-gray-500 hover:text-gray-500"
                            >
                              Upgrade Once
                            </span>
                            <span v-else> Upgrade Once </span>
                          </span>
                        </button>
                        <button
                          v-if="!tok.upgrading && parseInt(tok.balance / tok.upgradeReq) > 1"
                          :disabled="tok.balance < tok.upgradeReq || tok.upgrading"
                          class="text-blue-link hover:text-blue-hov block"
                          @click="upgrade(tok, tok.balance / tok.upgradeReq)"
                        >
                          <hr />
                          <span>
                            <span
                              v-if="tok.balance < tok.upgradeReq || state.loading"
                              class="line-through text-gray-500 hover:text-gray-500"
                            >
                              Upgrade All
                            </span>
                            <span v-else> Upgrade All </span>
                          </span>
                        </button>
                      </div>
                      <div v-if="batchSelect && tok.balance >= tok.upgradeReq">
                        <span v-if="tok.upgrading">
                          <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
                          Upgrading... Please wait up to 1 min for block confirmation.
                        </span>
                        <div
                          v-if="!tok.upgrading"
                          class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in"
                        >
                          <input
                            :id="'toggleUpgradeCheckbox' + tkey"
                            type="checkbox"
                            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                            @input="(e) => selectForBatch(e, tok)"
                          />
                          <label
                            :for="'toggleUpgradeCheckbox' + tkey"
                            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                          />
                        </div>
                        <label
                          v-if="!tok.upgrading"
                          :for="'toggleUpgradeCheckbox' + tkey"
                          class="text-xs text-gray-700 inline cursor-pointer"
                          >Upgrade?</label
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="pages > 0 && !upgrading" class="flex p-10">
                <div class="m-auto">
                  <button @click="prevPage()">
                    <fa
                      icon="chevron-circle-left"
                      class="text-blue-link hover:text-blue-hov h-5 w-5 mr-1"
                    /></button
                  ><span class="">Page {{ offset / limit + 1 }} of {{ pages }} </span
                  ><button @click="nextPage()">
                    <fa
                      icon="chevron-circle-right"
                      class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
                    />
                  </button>
                </div>
              </div>
              <div v-if="upgrading" class="nes-container lg:flex secondary-bg mt-5 text-left">
                <div class="flex-none invisible lg:visible pl-10">
                  <img alt="Lightning" src="../assets/badge.png" />
                </div>
                <div class="flex-initial px-5 text-center text-xl">
                  Enjoy this random gif while u wait. You can also go do other stuff without
                  affecting the transaction.
                  <img :src="nextGif" width="300" class="m-auto" />
                </div>
                <div class="flex-none invisible lg:visible pr-10">
                  <img alt="Lightning" src="../assets/badge.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="visibleTokens.length" class="lg:hidden divide-y divide-light-blue-400">
          <div
            v-for="(tok, tkey) in visibleTokens.slice(offset, offset + limit)"
            :key="tkey"
            class="block w-full h-20 text-left flex cursor-pointer"
            @click="$router.push('/token/' + tok.tokenId)"
          >
            <div class="flex-shrink-0 w-10 pt-3">
              <img
                class="w-10 squared-full"
                :class="{ 'vibrate-1': tok.upgrading }"
                :src="tok.metadata.displayUri"
                alt=""
              />
            </div>
            <div class="text-sm flex-grow p-1 pt-2">
              <span>{{ tok.metadata.shortName }}</span>
              <br /><span class="text-xs text-gray-500">{{ tok.balance }}x {{ tok.rarity }}</span>
              <br /><span class="text-xs text-gray-500">Token ID: {{ tok.tokenId }}</span>
            </div>
            <div class="flex-none p-5 pt-8">
              <router-link :to="'/token/' + tok.tokenId">
                <fa icon="chevron-right" class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1" />
              </router-link>
            </div>
          </div>
          <div v-if="pages > 0" class="flex p-10">
            <div class="m-auto">
              <button @click="prevPage()">
                <fa
                  icon="chevron-circle-left"
                  class="text-blue-link hover:text-blue-hov h-5 w-5 mr-1"
                /></button
              ><span class="">Page {{ offset / limit + 1 }} of {{ pages }} </span
              ><button @click="nextPage()">
                <fa
                  icon="chevron-circle-right"
                  class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Borders />
    </div>
    <div class="nes-container container-padding mt-10 bg-white">
      <div class="grid grid-cols-3 md:grid-cols-3">
        <div class="text-center col-span-12 lg:col-span-1">
          <img alt="Text Top Layer" src="../assets/text-top-layer.png" class="mx-auto pb-6 pt-6" />
          <span class="font-black text-4xl"
            >Level <br />
            Up!</span
          >
          <img
            alt="Text Top Layer"
            src="../assets/text-bottom-layer.png"
            class="mx-auto pb-6 pt-6"
          />
        </div>

        <div class="col-span-12 lg:col-span-2 text-left pl-10 text-sm">
          <p class="text-xl">Collect And Upgrade</p>

          <ul class="list-disc lg:px-10 leading-loose">
            <li class="text-lg">
              Collect and burn two commons
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[0].req_pxl, 0) }} $PXL</span
              >
              to mint one uncommon
            </li>
            <li class="text-lg">
              Collect and burn three uncommons
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[1].req_pxl, 0) }} $PXL</span
              >
              to mint one rare
            </li>
            <li class="text-lg">
              Collect and burn four rares
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[2].req_pxl, 0) }} $PXL</span
              >
              to mint one epic
            </li>
            <li class="text-lg">
              Collect and burn five epics
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[3].req_pxl, 0) }} $PXL</span
              >
              to mint one legendary
            </li>
            <li class="text-lg">
              Collect and burn 10 legendaries
              <span v-if="state.allRecipes.length" class="text-lg"
                >and pay {{ state.convertFromMutez(state.allRecipes[4].req_pxl, 0) }} $PXL</span
              >
              to mint one unique
            </li>
          </ul>
        </div>
      </div>

      <img alt="cards" src="../assets/cards.png" class="mx-auto mb-6 mt-6 hidden md:block" />

      <img alt="cards" src="../assets/cards-mobile.png" class="mx-auto mb-6 mt-6 block md:hidden" />

      <div class="nes-container secondary-bg mt-10 text-center mb-10 text-3xl">
        Your total FirePower is the combined FirePower of all the weapons you own.
      </div>

      <div class="grid grid-cols-3 md:grid-cols-3 pb-6 content-center items-center">
        <div class="text-center col-span-12 lg:col-span-1">
          <img alt="Text Top Layer" src="../assets/text-top-layer.png" class="mx-auto pb-6 pt-6" />
          <span class="font-black text-4xl"
            >The <br />
            Strongest <br />
            Are <br />
            Rewarded</span
          >
          <img
            alt="Text Top Layer"
            src="../assets/text-bottom-layer.png"
            class="mx-auto pb-6 pt-6"
          />
        </div>

        <div class="col-span-12 lg:col-span-2 text-left text-lg pl-10 align-middle">
          <p class="text-2xl">
            50% of all PixelPower claim & upgrade fees are shared back with the community each
            season:
          </p>
          <div
            v-if="!loading && state.season.end"
            class="flex align-center justify-center text-2xl"
          >
            <Countdown
              :prefix="'Season ends in '"
              :completed-msg="'Season ended! Rewards calculation is in progress...'"
              :timestamp="state.season.end"
              :countdown-classes="'text-2xl text-black'"
            />
          </div>
          <p class="text-2xl">
            🔫 20% is split among the top 10% of collectors with the highest all-time FirePower.
          </p>
          <p class="mt-10 text-2xl">
            🔫 20% is split among the top 10% of collectors with the most FirePower gathered during
            the current season.
          </p>
          <p class="mt-10 text-2xl">🔫 10% is shared back to those who refer their friends.</p>
        </div>
      </div>

      <img alt="cards fade" src="../assets/cards-fade.png" class="mx-auto pb-6 pt-6" />

      <Borders />
    </div>
    <div class="nes-container text-center container-padding bg-white mt-10">
      <img alt="Pixel Power" src="../assets/logo-new.png" class="mx-auto pb-6 pt-6" width="192" />
      <p class="text-xl">
        Each person can claim {{ state.numToText(maxFreeClaims) }} free air-dropped weapon at random
        each period. We only allow a limited number of claims per period, so it's important to get
        your claim in as early as possible so you don't miss out. The free weapon will be of common
        rarity.
      </p>
      <p class="text-xl">
        We also allow each person to purchase up to
        {{ state.numToText(maxPaidClaims) }} additional random weapons of common rarity for
        {{ state.convertFromMutez(claimingPrice, 0) }} $PXL each, limited to the available claims
        per period.
      </p>
      <p class="text-xl bold">
        You can trade your collection on
        <a class="text-xl" href="https://objkt.com/collection/artforge-pixelpower" target="_blank"
          >objkt.com</a
        >!
      </p>
      <p class="text-xl">
        Got some spare cards you are not collecting? Just list your tokens in the marketplace and
        someone else from the community may take you up on it.
      </p>
      <p class="text-xl">
        If you're missing a few cards to upgrade your collection, the marketplace might be a great
        shortcut to quickly grab what you need!
      </p>
      <img alt="Pixel Power" src="../assets/upgrade.png" class="mx-auto pb-6 pt-6" />

      <Borders />
    </div>
  </div>
</template>

<script>
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { debounce, findIndex } from 'lodash'
import VueMultiselect from 'vue-multiselect'
import { copyText } from 'vue3-clipboard'

import Borders from '../components/Borders'
import Countdown from '../components/Countdown.vue'

export default {
  name: 'MyCollection',
  components: {
    Borders,
    VueMultiselect,
    Countdown,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectWallet: Function,
  },
  data() {
    return {
      state: this.$root.$data.state,
      myTokens: this.$root.$data.state.myTokens,
      myTokensLoaded: this.$root.$data.state.myTokensLoaded,
      visibleTokens: [],
      offset: 0,
      limit: 20,
      confirmation: false,
      showHelp: true,
      upgrading: false,
      nextGif: null,
      batchSelect: false,
      batchTokens: [],
      rarities: [],
      raritiesOptions: ['common', 'uncommon', 'rare', 'epic', 'legendary', 'unique'],
      filter: '',
      showAliasInput: false,
      aliaserMaxLen: 15,
      settingAlias: false,
      aliaserCost: 250000,
      maxFreeClaims: 1,
      maxPaidClaims: 5,
      claimingPrice: 125000,
      showRefer: false,
      refLoading: false,
      refStatus: {
        isLoaded: false,
        bal: 0,
        qual: false,
        referredBy: false,
      },
      rewardsLoading: false,
      rewardsStatus: {
        bal: 0,
      },
      total: 0,
      myRank: -1,
    }
  },
  computed: {
    pages() {
      if (!this.myTokens) {
        return 1
      }
      let tot = parseInt((this.visibleTokens.length / this.limit).toFixed(0))
      if (tot < this.visibleTokens.length / this.limit) {
        tot++
      }
      return tot
    },
    myTokensTimestamp() {
      return this.state.myTokensTimestamp
    },
    shareUrl() {
      return `https://www.pixelpower.xyz?ref=${this.state.userAddress}`
    },
    percentile() {
      if (this.myRank > -1 && this.total) {
        const topTen = Math.round(this.total * 0.1)
        if (this.myRank <= topTen) {
          return `You are in the top 10% of collectors this season! Keep it up, and you'll be eligible for rewards at the end of the season.`
        } else {
          return `You're close! Keep building your firepower to make it into the top 10% (Rank ${topTen} or above)`
        }
      }

      return ''
    },
  },
  watch: {
    state: {
      handler(newValue) {
        if (newValue.myTokensLoaded !== this.myTokensLoaded) {
          this.myTokensLoaded = newValue.myTokensLoaded
          this.updateFilters()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.state.userAddress === null) {
      this.checkWallet()
    }
    this.state.log('mounted MyCollection', this.state.myTokens, this.state.myTokensLoaded)
    const sh = localStorage.getItem('showCollectionHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    this.updateFilters()

    const showRefer = localStorage.getItem('showRefer')
    this.showRefer = showRefer ? JSON.parse(showRefer) : false

    this.getMyStats()
    this.getClaimStorage()
    this.getReferralStatus()
    this.getRewardsStatus()
    this.getMyRank()
  },
  methods: {
    toggleShowRefer() {
      this.showRefer = !this.showRefer
      localStorage.setItem('showRefer', this.showRefer)
    },
    async genRefLink() {
      try {
        this.refLoading = true
        const powerReferrer = localStorage.getItem('powerReferrer')
          ? localStorage.getItem('powerReferrer')
          : process.env.VUE_APP_ROOT_REF
        const op = await this.state.ppReferrer.methods.set_referred_by(powerReferrer).send()
        await op.confirmation(2)
        await this.getReferralStatus()
      } catch (e) {
        this.refLoading = false
        console.error(e)
        // alert('Error: ' + e?.message)
      }
    },
    async claimRefBalance() {
      try {
        this.refLoading = true
        const op = await this.state.ppReferrer.methods.claim().send()
        await op.confirmation(2)
        if (this.state.userAddress !== process.env.VUE_APP_ROOT_REF) {
          const usrName = this.state.userAlias
            ? this.state.userAlias
            : this.state.formattedAddr(this.state.userAddress)
          const action = `${usrName} just claimed ${(this.refStatus.bal / 1000000).toFixed(
            0
          )} $PXL from referral rewards! :moneybag:`
          await axios({
            method: 'post',
            url: '/api/sendActivity',
            data: {
              content: action,
            },
          })
        }
        await this.getReferralStatus()
      } catch (e) {
        this.refLoading = false
        console.error(e)
        // alert('Error claiming rewards: ' + e?.message)
      }
    },
    async getReferralStatus() {
      try {
        this.refLoading = true
        const resp = await axios({
          url: '/api/referralStatus',
          params: {
            userAddress: this.state.userAddress,
          },
        })
        this.refStatus.bal = resp.data.bal
        this.refStatus.qual = resp.data.qual
        this.refStatus.referredBy = resp.data.referredBy
        this.refStatus.isLoaded = true
        this.refLoading = false
      } catch (e) {
        console.error(e)
        this.refLoading = false
        await new Promise((resolve) => setTimeout(resolve, 5000))
        this.getReferralStatus()
      }
    },
    async claimRewardsBalance() {
      try {
        this.rewardsLoading = true
        const bal = this.rewardsStatus.bal / 1000000
        const op = await this.state.ppDistributor.methods.claim().send()
        await op.confirmation(2)
        const usrName = this.state.userAlias
          ? this.state.userAlias
          : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} just claimed ${bal.toFixed(
          0
        )} $PXL from season rewards! :moneybag::moneybag::moneybag:`
        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: action,
          },
        })
        await this.getRewardsStatus()
      } catch (e) {
        this.rewardsLoading = false
        console.error(e)
        // alert('Error claiming rewards: ' + e?.message)
      }
    },
    async getRewardsStatus() {
      try {
        this.rewardsLoading = true
        const resp = await axios({
          url: '/api/rewardsStatus',
          params: {
            userAddress: this.state.userAddress,
          },
        })
        this.rewardsStatus.bal = resp.data.bal
        this.rewardsLoading = false
      } catch (e) {
        console.error(e)
        this.rewardsLoading = false
        await new Promise((resolve) => setTimeout(resolve, 5000))
        this.getRewardsStatus()
      }
    },
    copyShareUrl() {
      copyText(this.shareUrl, undefined, (error) => {
        if (error) {
          alert('Can not copy referral link')
          console.error(error)
        }
      })
    },
    async getMyRank() {
      try {
        const resp = await axios({
          url: '/api/myRank',
          params: {
            orderBy: 'weighted_season',
            owner: this.state.userAddress,
          },
        })
        this.myRank = resp.data.rank
        this.total = resp.data.total
      } catch (error) {
        this.state.log(error)
      }
    },
    toggleBatchSelect(on = null) {
      if (on) this.batchSelect = true
      this.updateFilters()
    },
    sortTokens(column) {
      this.state.sortTokens(column)
      this.updateFilters()
    },
    calcPxlCost(basePxl) {
      return (basePxl * this.state.pxlMod) / 1000
    },
    updateFilters() {
      this.debouncedUpdate()
    },
    debouncedUpdate: debounce(function () {
      this.offset = 0
      this.visibleTokens = this.myTokens.filter((t) => {
        let sRet = true
        if (
          this.filter !== '' &&
          t.metadata.name.toLowerCase().indexOf(this.filter.toLowerCase()) == -1
        ) {
          sRet = false
        }
        if (this.rarities.length && !this.rarities.includes(t.rarity)) {
          sRet = false
        }
        if (this.batchSelect && t.balance < t.upgradeReq) {
          sRet = false
        }
        return sRet
      })
    }, 50),
    handleBatchStop() {
      this.batchSelect = false
      this.batchTokens.length = 0
      this.updateFilters()
    },
    selectForBatch(e, tok) {
      if (e.target.checked) {
        if (this.batchTokens.length === 10) {
          alert('A max of 10 upgrades is allowed at once.')
          e.target.checked = false
          return
        }
        this.batchTokens.push(tok)
      } else {
        const idx = findIndex(this.batchTokens, (t) => t.tokenId == tok.tokenId)
        this.batchTokens.splice(idx, 1)
      }
    },
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.myTokens.length) {
        this.offset -= this.limit
      }
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {
        this.offset = 0
      } else {
        this.offset = offset
      }
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showCollectionHelp', JSON.stringify(false))
    },
    async getClaimStorage() {
      await this.state.fetchClaimMeta()

      this.claimingPrice = this.state.claimMeta.claimingPrice
      this.maxFreeClaims = this.state.claimMeta.maxFreeClaims
      this.maxPaidClaims = this.state.claimMeta.maxPaidClaims
    },
    async upgrade(tokens, times = 1) {
      if (!Array.isArray(tokens)) {
        tokens = [tokens]
      }
      if (tokens.length == 0) {
        return
      }
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      const transactions = []

      const powerReferrer = localStorage.getItem('powerReferrer')
      if (powerReferrer && this.refStatus.referredBy === false) {
        transactions.push({
          kind: OpKind.TRANSACTION,
          ...this.state.ppReferrer.methods.set_referred_by(powerReferrer).toTransferParams(),
        })
      }

      const pixelOp = [
        {
          add_operator: {
            owner: this.state.userAddress,
            operator: process.env.VUE_APP_PP_CRAFTER,
            token_id: 0,
          },
        },
      ]
      transactions.push({
        kind: OpKind.TRANSACTION,
        ...this.state.ppPixel.methods.update_operators(pixelOp).toTransferParams(),
      })
      this.upgrading = true
      this.state.loading = true
      try {
        let op = null
        for (let i = 0; i < tokens.length; i++) {
          const token = tokens[i]
          token.upgrading = true
          const recipe = token.recipe

          const operators = recipe.req_items.map((item) => {
            return {
              add_operator: {
                owner: this.state.userAddress,
                operator: process.env.VUE_APP_PP_CRAFTER,
                token_id: item.token_id,
              },
            }
          })
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppMain.methods.update_operators(operators).toTransferParams(),
          })

          for (let i = 0; i < times; i++) {
            transactions.push({
              kind: OpKind.TRANSACTION,
              ...this.state.ppCrafter.methods.craft(recipe.id).toTransferParams(),
            })
          }
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        op = await batch.send()
        await op.confirmation(1)

        this.state.loading = false
        this.confirmation = true
        setTimeout(() => {
          this.confirmation = false
        }, 30000)

        const usrName = this.state.userAlias
          ? this.state.userAlias
          : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} upgraded ${tokens.length} weapon${
          tokens.length > 1 ? 's' : ''
        }. :fire:`
        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: action,
          },
        })

        tokens.map((token) => {
          token.balance -= token.upgradeReq
          token.upgrading = false
          if (token.balance === 0) {
            const idx = findIndex(this.myTokens, (mt) => {
              return mt.tokenId === token.tokenId
            })
            this.myTokens.splice(idx, 1)
          }
        })
        const tokenIds = tokens.map((t) => t.recipe.crafted_item.token_id)

        this.state.sortTokens(this.state.sortBy, true)
        this.$forceUpdate()
        setTimeout(() => {
          this.state.loadMyTokens(true, true)
        }, 15000)

        this.upgrading = false
        this.handleBatchStop()
        // this.$router.push(`/token/${upgradedTokenId}`)
        this.$router.push({
          path: '/congrats',
          query: {
            tokenIds,
          },
        })
      } catch (e) {
        tokens.map((token) => (token.upgrading = false))
        this.upgrading = false
        this.state.loading = false
        this.handleBatchStop()
        console.error('Unable to upgrade token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
    async getMyStats() {
      const resp = await axios({
        url: '/api/myStats',
        params: {
          owner: this.state.userAddress,
        },
      })
      this.state.myStats = resp.data.myStats
    },
    async setAlias() {
      try {
        if (this.state.userAddress === null) {
          return
        }
        this.aliasInput = this.aliasInput.trim()
        if (this.aliasInput.length > this.aliaserMaxLen) {
          alert(
            'Max alias length is 15 chars. If you feel this is too restrictive, please reach out to an admin on discord.'
          )
          return
        }
        if (this.aliasInput.length < 3) {
          alert(
            'Min alias length is 3 chars. If you feel this is too restrictive, please reach out to an admin on discord.'
          )
          return
        }
        this.settingAlias = true

        const aliaser = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_ALIASER)

        const opts = {}
        opts.amount = this.aliaserCost
        opts.mutez = true
        let op = await aliaser.methods.set_alias(this.aliasInput).send(opts)
        await op.confirmation(1)

        this.state.userAlias = this.aliasInput
        this.settingAlias = false
        this.showAliasInput = false

        await axios({
          method: 'post',
          url: '/api/addAlias',
          data: {
            userAddress: this.state.userAddress,
            userAlias: this.state.userAlias,
          },
        })

        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: `${this.state.formattedAddr(this.state.userAddress)} is now known as '${
              this.aliasInput
            }' :detective:`,
          },
        })
      } catch (e) {
        this.settingAlias = false
        console.error('Unable to connect wallet', e)
      }
    },
    async checkWallet() {
      await this.connectWallet()
      if (this.state.userAddress === null) {
        this.$router.push('/')
      } else {
        this.$router.push('/claim')
      }
    },
  },
}
</script>
