<template>
  <div class="nes-container primary-bg">
    <div class="nes-container bg-white">
      <div class="grid grid-cols-1 items-left justify-between mt-12 mb-12">
        <h2 class="text-2xl pb-5">Privacy Policy</h2>
        <div class="text-left w-full lg:w-11/12 m-auto">
          <pre>
            1. Purpose
              This Privacy Policy explains how Pixel Global, Ltd. (the “Company”) collects, uses, stores, and discloses your personal 
              information. It also describes the choices available to you concerning the use of, your access to, and how to update 
              and correct your personal information. 

            2. Scope
              This Privacy Policy applies to personal information that the Company collects when you use or access the site.

              “Personal information” is defined as any information about an identifiable individual.
              Personal information will be handled by the Company in accordance with applicable law and this Privacy Policy.

            3. Consent
              When you create an account or sign up to join the waiting list or email marketing list, the Company will ask you to 
              provide the necessary personal information required, and you consent under the lawful basis of performance of a contract 
              in order for the Company to provide you with the information and services you are requesting. 

            4. Security
              The Company takes commercially reasonable measures to help safeguard your personal information from loss, disclosure, 
              alteration, misuse, and unauthorized access, theft, and destruction. This includes, when required or appropriate and 
              feasible, obtaining written assurances from third parties that may access your data that they will protect the data 
              with adequate safeguards. The Internet is not 100% secure. The Company has no control over the security protocols for 
              Internet networks that may be used by you. The Company cannot guarantee the absolute security of your information, 
              and we do not accept liability for unintentional disclosure. The Company is not responsible for the security of 
              information that you transmit to us over networks that the Company does not control, including the Internet and 
              wireless networks.

            5. Collection of Information
              The Company collects the following types of information:
              Information that you provide to the Company directly to deliver information as requested. Such information includes, 
              but may not be limited to:
                Your web browser information (type, version)
                Your wallet address (when connected to a wallet),
                Your IP address and your geographic location.

            6. Marketing
              Users who voluntarily provide us with their personal details in order to receive additional information will receive 
              regular marketing emails in relation to the site, Non-Fungible Token (“NFT”) information, and any offered services. 
              Such marketing messages may be delivered to those users who choose to receive them via personal contact such as email 
              or text message. Users may change their preferences to withdraw their consent to receive these personal messages 
              at any time. All marketing emails will contain a link to unsubscribe. 

            7. Cookie Policy
              A cookie is a small text file that is placed and stored on your computer, mobile, or other devices by webpages that 
              you visit. The cookies collect information in an anonymous form, including the number of visitors to the webpage, 
              how the visitor found the webpage, and recent webpages visited. All cookies used by the Company are in accordance 
              with current regulations. Before cookies are placed on your device, you will be shown a pop-up requesting your consent 
              to the Company’s use of cookies. Your consent enables the Company to provide the best possible service to you. If you 
              do not consent to the use of cookies, certain features of the Company service may not function as intended. You are 
              also able to delete cookies from your device at any time.

              The Company may also use any of the following categories of cookies:

                A. Essential Cookies
                  The cookies are strictly necessary for the Company site to function. They are usually only set in response to 
                  actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, 
                  or filling in forms. These cookies do not store any personally identifiable information.

                B. Analytics Cookies
                  These cookies track information about visits to the site so that we can make improvements and report performance. 
                  They help us to know which pages are the most and least popular and see how visitors move around the site. 
                  All information these cookies collect is aggregated and anonymous.

                C. Functional or Preference Cookies
                  These cookies enable the site to provide enhanced functionality and personalisation. They are used to remember 
                  information you have entered or choices you make (i.e. username or language) on the site. They may be set by 
                  the Company or by third party providers whose services have been added to pages on the site.

            8. Use of Information
              The Company may use the information collected from you for the following purposes:
              The Company may use personal information to provide products, services, or information requested by you. 
              Examples include: to provide information regarding NFTs and, if requested, to provide our supporting partners 
              with your information for the purpose of providing services you, including NFT solutions, and to respond to your inquiries.
              The Company may use personal information for the following administrative purposes:
              · Measure interest in NFT solutions
              · Monitor metrics such as total number of visitors, traffic, and demographic patterns applicable to the services
              · Compliance with legal obligations and regulatory requirements (i.e., respond to legal process or request for 
                information issued by government authorities)
              · Prevent potentially prohibited or illegal activities
              · Enforce the Company and/or site Terms and Conditions 

            9. Sharing of Information
              The Company will not rent, share, transfer, or sell your information to third parties without your consent or as agreed 
              for the purposes of delivering the services as noted in this Privacy Policy. Should you, the user, request information 
              regarding NFT solutions, the Company will provide your information to our supporting partners for that purpose only.

            The Company may disclose information collected in the following circumstances or as otherwise described
            in this Privacy Policy:

              <b>With Vendors and Service Providers</b>
                The Company may disclose your information to third party service providers that perform services on behalf of the Company, 
                such as web-hosting companies, analytics providers, and information technology providers. The Company entrusts personal 
                information to the following types of service providers:
                  · Providers of IT and related services
                  · Customer service activities
                  · Fraud prevention
                  · Providers of services you have requested
                  
              <b>With Law Enforcement or Government Authorities</b>

                The Company may disclose information as may be permitted or required by laws of any jurisdiction that may apply 
                to the Company, as provided for under contract, or as the Company deems reasonably necessary to provide services 
                in accordance with applicable law. In these circumstances, the Company takes reasonable efforts to notify you prior 
                to disclosing information that may reasonably identify you, unless prior notice is prohibited by applicable law or 
                is not possible or reasonable in the circumstances.

            10.Transfer of Data
              The Company takes all reasonable steps to ensure that the data we collect under this privacy statement is processed 
              according to the provisions of this statement and the requirements of applicable law wherever the data is located.
              Circumstances under which your data could be transferred include when it is necessary for the conclusion or 
              performance of a contract between ourselves and a third party and the transfer is in your interest for the purposes 
              of the contract and where you have consented to the data transfer.

            11. Individual’s Data Rights
              We respect the rights and freedoms of individuals, and as such, we would like to make you aware of the following:
              You have the right to:
              · Request access to your data
              · Request rectification of your data where there are errors or inaccuracies, or the data is not current
              · Request that the data we hold is removed entirely from our systems (the right to have data removed is only 
                applicable where it does not conflict with our legal and regulatory requirements to keep certain records according 
                to the data retention period)
              · Request us to restrict processing of your data
              · Object to our processing of your data
              · Request your data in a format that is commonly used/accepted
              · Send your data to another controller
              · Withdraw consent already provided – at any time
              You also have the right to complain to us. To lodge a complaint or to exercise any of your rights listed above, 
              please contact us at support@pxl.limited.

            12.Third Party Services and Content
              The third-party vendors with which the Company may share your personal information are contractually required to 
              keep this information confidential and may generally not use such information for any purpose other than to help 
              provide services you request.

            13.Retention
              Personal information will only be kept for as long as is required to provide the requested information and 
              services to the user. 

            14.Updates or Changes to The Company Policies
              The Company may modify or update this Privacy Policy from time to time, so please review it periodically. 
              Your continued use of the Company site after any modification to this Privacy Policy will constitute your acceptance 
              of such modification.

            15.Your Choices and Rights
              Subject to local law, you may have certain rights regarding information that the Company has collected and that 
              is related to you. Please contact the Company to update or correct your information if it changes or if you believe 
              that any information that we have collected about you is inaccurate or out of date. You can also ask to see what 
              personal information the Company retains about you, and to erase your personal information. If you would like to 
              exercise the rights you may have, please contact us at support@pxl.limited.

              Although the Company strives to provide individuals with access to their personal information, there may be instances 
              where the Company may not provide access, including but not limited to, where the information would put another 
              individual’s privacy at risk, where the access would reveal confidential commercial information, or the requested 
              information contains legal privilege. If the Company determines that access should be limited in any specific instance, 
              the Company will provide an explanation of why that assessment has been made and a contact point for any further concerns. 

            16.Children’s Privacy
              The Company does not knowingly collect or solicit any information from anyone under the age of 13, or from a minor 
              in a jurisdiction where parental/guardian consent for the collection of information is required until the age of majority 
              in that jurisdiction, or knowingly allow such persons to register for the service. The service and its content are not 
              directed at children under the age of 13. In the event that the Company learns that it has personal information from a 
              child under the age of 13 without parental consent, or from a minor in a jurisdiction where parental / guardian consent 
              for the collection of information is required until the age of majority in that jurisdiction, the Company will delete 
              that information as quickly as possible. If you believe that the Company might have any information from or about a 
              child that requires parental or guardian consent, please contact the Company at support@pxl.limited.

            17.California Consumer Protection Act of 2018
              This section of the privacy policy and notice for California residents applies solely to visitors, users, and individuals 
              who reside in the State of California (“consumers” or “you”). 
              We have adopted this notice to comply with the California Consumer Privacy Act of 2018 (CCPA). 
              The terms “business”, “business purpose”, “personal information”, “processing”, and “service provider” have the meanings 
              giving to them in the CCPA.
              The Company and all affiliates and subsidiaries may collect and use consumer personal information for their commercial 
              and business purposes and related operational purposes. With respect to these activities, we may have direct responsibilities 
              as a business subject to the CCPA. For example, for certain activities related to direct interaction with consumers who have 
              a direct relationship with us, interacting with prospective/current clients, marketing, websites/web applications use, engaging 
              vendors, and interacting with visitors/users of our site. In these cases, this privacy policy is applicable.

              Categories of Personal Information We Collect
                Regarding the specific personal information that we collect, use, and disclose relating to a California resident covered by the CCPA, 
                the Company never sells or discloses your personal information to third parties unless it is required to do so in order to fulfil a 
                request made by you.

                We may collect and use for business purposes, personal information relating to California residents covered by this policy which 
                may include:
                  · Name, alias, postal address, unique personal identifier, online identifier, internet protocol address (IP Address), email address, 
                    or other similar identifiers
                  · Personal information categories described in California Customer Records statute (Cal. Civ. Code § 1798.80(e))
                  · Name, address and telephone number
                  · Internet/network activity
                  · Browsing history and information regarding a consumer’s interaction with an internet website, application, or advertisement
                  · Geolocation data
                  · Physical location or movements
                  · Inferences drawn from other personal information
                  · Inferences drawn to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, 
                    preferences, predispositions, behaviour, attitudes, intelligence, abilities, and aptitudes
                Sources of Personal Information We Collect
                · Information directly from you, the consumer, that you choose to provide when you interact with us
                · Information we automatically collect when you interact with our site, content, or emails such as your IP address and the 
                  pages you visited, and when you use our services, we may collect information on how you use those services; please see our 
                  Section 7 of this Policy for information our use of cookies additional information
                · Information from other sources, including service providers or publicly available sources
                Business Purposes
                We collect personal information to offer and administer our services and products. These include:
                · To provide the products or perform services for our clients pursuant to a written agreement, including contacting you in 
                  the course of our ordinary commercial relationship, verifying client information, or similar activities as needed to provide 
                  services for or on behalf of our clients.
                · For marketing purposes. For example, where you have previously opted in, we may use your information to further discuss your 
                  interest in our services and to send you information regarding NFTs and other such services and opportunities. You may opt-out 
                  of receiving email communications and updates at any time. 
                · To improve our communications with you. Emails sent to you by the Company may include standard tracking, including open and 
                  click activities. The Company may collect information about your activity as you interact with our email messages and related 
                  content in order to verify or maintain the quality of our communications, or improve, upgrade, or enhance the services we provide.
                · For operating and improving the Company and your customer experience, including debugging to identify and repair errors that 
                  impair existing intended functionality or to verify or maintain the quality of our site, and to improve, upgrade, or enhance 
                  the site and/or services. For example, we may collect and analyse data on your use of our site and process it for the purpose 
                  of improving online user experience. Please see our Terms and Conditions for additional information.
                · For security purposes, including detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal
                  activity, and prosecuting those responsible for that activity.
                · For other business purposes, which will be disclosed at the time we collect personal information.
                Disclosure/Sharing of Personal Data
                We do not sell any personal information.
                We share personal information for business purposes with the following categories of third parties:
                · Our affiliates or partners, as needed to operate our business and provide services.
                · Service providers, such as support partners, consultants, and other service providers who perform certain services on behalf of 
                the Company, in which case we enter into a contract that describes the purpose of processing and requires the recipient to not use 
                it for any purpose except performing the contract.
                · Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services 
                  we provide to you.
                Your Rights
                Subject to the CCPA and other applicable law, you have the following rights concerning your data processed by the Company:
                · Access: You have the right to request to access the personal information that the Company holds about you, including categories 
                  of information collected, categories of sources from which the information is collected, our business purpose for collecting information, 
                  and categories of third parties with whom we share your information.
                · Deletion: You can request that the Company erase your personal data. Please see Section 11 above.
                · Non-discrimination: The Company will not discriminate against a consumer because the consumer exercised any of the consumer’s 
                  rights under the CCPA.

            18.Contact Us
              If you have any questions or concerns about this Privacy Policy, please contact the Company at support@pxl.limited.

            This Privacy Policy may be changed by the Company at any time. You are advised to check this policy regularly for any changes.

            This Privacy Policy was updated on October 29, 2021
          </pre>
        </div>
      </div>
      <Borders />
    </div>
  </div>
</template>
<script>
import Borders from '../components/Borders'
export default {
  name: 'Disclaimer',
  components: {
    Borders,
  },
}
</script>
<style scoped>
b {
  font-family: none;
}
</style>
