<template>
  <header id="nav" class="top-0 z-40 bg-transparent relative">
    <Crest :state="state" :connect-wallet="connectWallet" />
    <!-- <div
      :class="
        state.userAddress
          ? 'md:grid-cols-3 lg:grid-cols-6 header-connected'
          : 'md:grid-cols-4 lg:grid-cols-4 header-unconnected'
      "
      class="top-4 md:grid lg:grid-rows-1 items-center justify-between bg-blue-lightest border-4 border-black uppercase"
    > -->
    <div
      class="top-4 md:grid md:grid-cols-4 lg:grid-cols-7 header-connected lg:grid-rows-1 items-center justify-between bg-blue-lightest border-4 border-black uppercase"
    >
      <span class="nav-left" />
      <div>
        <burger-menu
          :is-open="menu"
          :toggle-open="
            () => {
              menu = !menu
            }
          "
          class="top-0 right-0 mt-2 block md:hidden flex justify-end"
        />
      </div>
      <span v-for="(link, i) in navLinks" :key="i">
        <router-link
          v-if="link.path"
          class="font-normal text-black hover:text-light-blue-900 hidden md:block"
          :to="link.path"
        >
          {{ link.label }}
        </router-link>
        <a
          v-if="link.url"
          class="font-normal text-black hover:text-light-blue-900 hidden md:block"
          :href="link.url"
          target="_blank"
        >
          {{ link.label }}
        </a>
      </span>
      <span class="nav-right" />
    </div>
  </header>
</template>

<script>
import BurgerMenu from './components/BurgerMenu.vue'
import Crest from './components/Crest.vue'
import links from './components/nav-links'
export default {
  name: 'Header',
  components: {
    BurgerMenu,
    Crest,
  },
  props: {
    connectWallet: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      state: this.$root.$data.state,
      menu: false,
    }
  },
  computed: {
    navLinks() {
      return links(this.state)
    },
  },
  // methods: {
  //   async checkWallet(link) {
  //     console.log(link)
  //     if (link.wallet) {
  //       if (!this.state.userAddress) {
  //         this.$router.push({ path: link.path })
  //         this.connectWallet()
  //       }
  //     }
  //   },
  // },
}
</script>

<style scoped>
.header-unconnected {
  padding: 20px;
}
.header-connected {
  padding: 20px;
}
.nav-left {
  position: absolute;
  width: 70px;
  height: 90px;
  background: url('assets/nav-corner-left.png') no-repeat;
  top: 140px;
  left: -60px;
}

.nav-right {
  position: absolute;
  width: 70px;
  height: 90px;
  background: url('assets/nav-corner-right.png') no-repeat;
  top: 140px;
  right: -66px;
}
.router-link-active {
  text-decoration: underline;
}
@media handheld, only screen and (max-width: 1024px) {
  .nav-left,
  .nav-right {
    display: none;
  }
}
@media handheld, only screen and (max-width: 739px) {
  .nav-left,
  .nav-right {
    display: none;
  }
}
</style>
