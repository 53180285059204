<template>
  <div class="text-center w-full">
    <div class="p-5">
      <BottomLinks />
    </div>
  </div>
</template>
<script>
import BottomLinks from './BottomLinks'

export default {
  components: {
    BottomLinks,
  },
}
</script>
