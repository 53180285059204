<template>
  <div class="countdown-outer">
    <h1 v-if="completed" class="countdown-complete">{{ completedMsg }}</h1>
    <p v-else class="text-2xl text-yellow-500 text-center">
      {{ prefix }}<br />{{ days > 0 ? days + 'd' : '' }} {{ hours > 0 ? hours + 'h' : '' }}
      {{ minutes > 0 ? minutes + 'm' : '' }} {{ seconds > 0 ? seconds + 's' : 0 }}
    </p>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Countdown',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    timestamp: {
      type: moment,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    completedMsg: {
      type: String,
      default: '',
    },
    countdownClasses: {
      type: String,
      default: 'text-2xl text-black',
    },
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: null,
      diff: 0,
      interval: null,
      completed: false,
    }
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60
    },

    minutes() {
      return Math.trunc(this.diff / 60) % 60
    },

    hours() {
      return Math.trunc(this.diff / 60 / 60) % 24
    },

    days() {
      return Math.trunc(this.diff / 60 / 60 / 24)
    },
  },
  watch: {
    now() {
      this.diff = this.date - this.now
      if (this.diff <= 0 || this.stop) {
        this.diff = 0
        // Remove interval
        clearInterval(this.interval)
        this.completed = true
      }
    },
  },
  mounted() {
    this.processTime()
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    processTime() {
      if (!this.timestamp || !this.timestamp.isValid()) {
        throw new Error("Missing props 'deadline'")
      }

      this.date = this.timestamp.unix()

      this.interval = setInterval(() => {
        this.now = Math.trunc(new Date().getTime() / 1000)
      }, 1000)
    },
  },
}
</script>
