<template>
  <div class="md:container md:mx-auto text-center px-4 font-px h-screen">
    <Header :connect-wallet="connectWallet" />

    <main class="flex-1 overflow-y-auto">
      <router-view v-slot="{ Component }">
        <transition>
          <div>
            <component :is="Component" :connect-wallet="connectWallet" />
          </div>
        </transition>
      </router-view>
      <teleport to="body">
        <vue3-snackbar bottom left :duration="7500"></vue3-snackbar>
      </teleport>
      <History
        :is-open="state.history.isOpen"
        :toggle-open="
          () => {
            state.history.isOpen = !state.history.isOpen
          }
        "
      />
    </main>
    <Footer />
  </div>
</template>

<script>
import { BeaconEvent, defaultEventCallbacks } from '@airgap/beacon-sdk'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { compose, TezosToolkit } from '@taquito/taquito'
import { tzip12, Tzip12Module } from '@taquito/tzip12'
import { tzip16, Tzip16Module } from '@taquito/tzip16'
import axios from 'axios'
import { find, findIndex, forEach, groupBy } from 'lodash'
import { isMobile } from 'mobile-device-detect'
import moment from 'moment'
import Pusher from 'pusher-js'

import Borders from './components/Borders'
import Footer from './components/Footer'
import Header from './Header'
import History from './views/History'

const store = {
  state: {
    contract: process.env.VUE_APP_PP_MAIN,
    debug: false, //process.env.VUE_APP_DEBUG,
    isMobile: isMobile,
    tezos: null,
    ppMain: null,
    ppMarket: null,
    ppAuction: null,
    ppClaimer: null,
    ppCrafter: null,
    ppPixel: null,
    ppReferrer: null,
    ppDistributor: null,
    userAddress: null,
    userAlias: null,
    balance: 0,
    pxlBalance: 0,
    wallet: null,
    loading: false,
    allTokens: [],
    allRecipes: [],
    stats: null,
    statsTimestamp: null,
    myStats: null,
    myTokens: [],
    myTokensLoaded: false,
    myTokensLoading: true,
    myTokensTimestamp: null,
    sortBy: 'tokenId',
    sortDir: true,
    refreshTimeout: 15000,
    seriesMeta: null,
    tradingFee: 25 / 1000,
    storageLimit: process.env.VUE_APP_STORAGE_LIMIT,
    geoLoaded: false,
    canParticipate: true,
    agreedToDisclaimer: false,
    termsVersion: '202204190841',
    aliases: [],
    history: {
      items: [],
      limit: 25,
      offset: 0,
      isOpen: false,
    },
    gifs: [
      'https://i.gifer.com/D4c.gif',
      'https://i.gifer.com/76Oh.gif',
      'https://i.gifer.com/EQyr.gif',
      'https://media.giphy.com/media/gYkga3bZav66I/giphy.gif',
      'https://c.tenor.com/fT3iPXvDCv0AAAAd/gun-fail-guns.gif',
      'https://c.tenor.com/lC8D8fsv2ZAAAAAC/lock-and-load-lock.gif',
      'https://i.gifer.com/3XDB.gif',
      'https://i.gifer.com/3m26.gif',
      'https://i.gifer.com/XqrN.gif',
      'https://i.gifer.com/KUQy.gif',
      'https://i.gifer.com/JolQ.gif',
      'https://i.gifer.com/7Dvd.gif',
      'https://i.gifer.com/3R82.gif',
      'https://i.gifer.com/SkJn.gif',
      'https://i.gifer.com/8CWO.gif',
      'https://i.gifer.com/75rT.gif',
      'https://i.gifer.com/O7PD.gif',
      'https://media.giphy.com/media/A9grgCQ0Dm012/giphy-downsized-large.gif',
      'https://media.giphy.com/media/3o84sq21TxDH6PyYms/giphy.gif',
      'https://media.giphy.com/media/JmymEj844h8KWQiPay/giphy.gif',
      'https://media.giphy.com/media/3otPoIR7tGymabNvS8/giphy.gif',
      'https://media.giphy.com/media/Ri9WBdFdDVEpVUfE07/giphy.gif',
      'https://media.giphy.com/media/XG7ceWVV9krPFsFohg/giphy.gif',
      'https://media.giphy.com/media/oe33xf3B50fsc/giphy.gif',
      'https://media.giphy.com/media/z9iE1SPAptyLK/giphy.gif',
      'https://media.giphy.com/media/26FmPUB5MInUrIjvy/giphy.gif',
      'https://media.giphy.com/media/9umH7yTO8gLYY/giphy.gif',
      'https://media.giphy.com/media/7IvTtIkZoQ9aM/giphy.gif',
      'https://media.giphy.com/media/l4FGslLMQbqZbHBIc/giphy.gif',
      'https://media.giphy.com/media/Edbkk7WKv7iEg/giphy.gif',
      'https://49.media.tumblr.com/6431ac75f8e65bba4df35622d2aefafe/tumblr_o4jue1efH11tqh2yyo1_500.gif',
      'https://tenor.com/00rW.gif',
    ],
    formattedAddr(address) {
      return 'ꜩ' + address.substring(0, 5) + '..' + address.substring(address.length - 5)
    },
    convertFromMutez: (amt, fixed = 3) => {
      return (amt / 1000000).toFixed(fixed)
    },
    convertToMutez: (amt) => {
      return amt * 1000000
    },
    async getPxlMod() {
      const resp = await axios({
        url: '/api/pxlMod',
      })
      this.pxlMod = resp.data.pxlMod
    },
    numToText: (num) => {
      switch (num) {
        case 1:
          return 'one'
        case 2:
          return 'two'
        case 3:
          return 'three'
        case 4:
          return 'four'
        case 5:
          return 'five'
        case 6:
          return 'six'
        case 7:
          return 'seven'
        case 8:
          return 'eight'
        case 9:
          return 'nine'
        case 0:
          return 'zero'
        default:
          return num
      }
    },
    getRarityNum: (rarity) => {
      switch (rarity) {
        case 'common':
          return 1
        case 'uncommon':
          return 2
        case 'rare':
          return 3
        case 'epic':
          return 4
        case 'legendary':
          return 5
        case 'unique':
          return 6
      }
    },
    getNextRarity: (rarity) => {
      switch (rarity) {
        case 'common':
          return 'uncommon'
        case 'uncommon':
          return 'rare'
        case 'rare':
          return 'epic'
        case 'epic':
          return 'legendary'
        case 'legendary':
          return 'unique'
      }
    },
    sortTokens(sortBy, ignore = false) {
      if (this.sortBy == sortBy && !ignore) {
        this.sortDir = !this.sortDir
      }
      this.sortBy = sortBy

      this.myTokens.sort((a, b) => {
        switch (sortBy) {
          case 'rarity':
            const numA = this.getRarityNum(a.rarity)
            const numB = this.getRarityNum(b.rarity)
            if (this.sortDir) return numA - numB
            return numB - numA
          case 'balance':
            if (this.sortDir) return a.balance - b.balance
            return b.balance - a.balance
          default:
            if (this.sortDir) return ('' + a.metadata.name).localeCompare(b.metadata.name)
            return ('' + b.metadata.name).localeCompare(a.metadata.name)
        }
      })
    },
    log(...msg) {
      if (this.debug) {
        console.log(...msg)
      }
    },
    async disconnect() {
      await this.wallet.client.clearActiveAccount()
      this.userAddress = null
      this.myTokens = []
      this.myTokensLoaded = false
      localStorage.removeItem('myTokens')
      this.userAlias = null
      localStorage.removeItem('myAlias')
    },
    async loadBalance() {
      const bal = await this.tezos.tz.getBalance(this.userAddress)
      this.balance = bal.toNumber()

      const pxlResp = await axios('/api/pxlBalance?userAddress=' + this.userAddress)
      this.pxlBalance = pxlResp.data.balance
    },
    async loadStats(force = false) {
      let lastLoad = moment()
      const ls = localStorage.getItem('stats')
      if (ls) {
        const parsedStats = JSON.parse(ls)
        this.stats = parsedStats.stats
        lastLoad = moment(parsedStats.timestamp)
        this.statsTimestamp = lastLoad
      }
      if (this.stats === null || lastLoad.isBefore(moment().subtract(1, 'hour')) || force) {
        const statsResp = await axios({
          url: '/api/stats',
        })

        const allCards = groupBy(this.allTokens, 'seriesId')
        let series = []
        forEach(allCards, (tokens, seriesId) => {
          let title = tokens[0].metadata.name
          for (let i = 0; i < tokens.length; i++) {
            const tokStat = find(statsResp.data.stats, (ts) => ts.tokenId == tokens[i].tokenId)
            if (tokStat) {
              tokens[i].supply = tokStat.supply
              tokens[i].burned = tokStat.burned
              tokens[i].minted = tokStat.minted
            }
          }
          series.push({
            seriesId,
            title,
            displayUri: tokens[0].metadata.displayUri,
            tokens: tokens.sort((a, b) => {
              return a.tokenId - b.tokenId
            }),
          })
        })
        series.sort((a, b) => {
          return a.title.localeCompare(b.title)
        })
        this.stats = {
          series,
        }
        localStorage.setItem(
          'stats',
          JSON.stringify({ stats: this.stats, timestamp: new Date().toISOString() })
        )
        this.statsTimestamp = moment()
      }
    },
    async loadAllTokens(force = false) {
      const allTokens = localStorage.getItem('allTokens')
      if (allTokens) {
        this.allTokens = JSON.parse(allTokens).tokens
      }
      if (force) {
        const tokResp = await axios({
          url: '/api/allTokens',
        })
        this.allTokens = tokResp.data.tokens.map((t) => {
          const recipe = find(this.allRecipes, (r) => r.id === t.recipeId)
          if (recipe) {
            t.recipe = recipe
            t.upgradeReq = recipe.req_items[0].amount
            t.upgradeCost = recipe.req_pxl
            t.upgradeTo = this.getNextRarity(t.rarity)
          }
          return t
        })
        this.allTokens = this.allTokens.sort((a, b) => {
          // const res2 = '' + a.metadata.name.localeCompare(b.metadata.name)
          // return res2 === 0 ? a.tokenId - b.tokenId : res2
          return a.tokenId - b.tokenId
        })
        localStorage.setItem('allTokens', JSON.stringify({ tokens: this.allTokens }))
      }
    },
    async loadAllRecipes() {
      const recResp = await axios({
        url: '/api/allRecipes',
      })
      this.allRecipes = recResp.data.recipes
    },
    async loadMyTokens(force = false, skipCache = false) {
      const myTokens = localStorage.getItem('myTokens')
      if (myTokens && !skipCache) {
        const mt = JSON.parse(myTokens)
        if (mt.timestamp && moment(mt.timestamp).isAfter(moment().subtract(30, 'minutes'))) {
          this.myTokens.length = 0
          this.myTokens.push(...mt.tokens)
          this.myTokensLoaded = true
          this.myTokensTimestamp = moment(mt.timestamp)
        }
      }

      if (!this.userAddress) {
        return
      }

      this.loadBalance()

      if (!this.myTokensLoaded || force) {
        this.myTokensLoading = true
        const tokResp = await axios({
          method: 'post',
          url: '/api/myTokens',
          data: {
            allTokens: this.allTokens,
            userAddress: this.userAddress,
          },
        })

        tokResp.data.tokens.forEach((tok) => {
          const idx = findIndex(this.myTokens, (mt) => mt.tokenId === tok.tokenId)
          if (idx > -1) {
            this.myTokens[idx].balance = tok.balance
          } else {
            this.myTokens.push({ ...tok, upgrading: false })
          }
        })

        for (var i = 0; i < this.myTokens.length; i++) {
          const idx = findIndex(tokResp.data.tokens, (tok2) => {
            return tok2.tokenId === this.myTokens[i].tokenId
          })
          if (idx === -1) {
            this.myTokens.splice(i, 1)
            i--
          }
        }
        this.sortTokens(this.sortBy, true)
        this.myTokensLoaded = true
        this.myTokensTimestamp = moment()
        this.myTokensLoading = false

        localStorage.setItem(
          'myTokens',
          JSON.stringify({
            tokens: this.myTokens.map((t) => {
              delete t.upgrading
              return t
            }),
            timestamp: this.myTokensTimestamp.toISOString(),
          })
        )
      }
    },
    async loadMyAlias() {
      const myAlias = localStorage.getItem('myAlias')
      if (myAlias) {
        this.userAlias = JSON.parse(myAlias).alias
      }

      const alias = find(this.aliases, (a) => {
        return a.address === this.userAddress
      })
      if (alias) {
        this.userAlias = alias.alias
        localStorage.setItem('myAlias', JSON.stringify({ alias: this.userAlias }))
      }
    },
    async loadDisclaimer() {
      const disclaimer = localStorage.getItem('disclaimer')
      if (disclaimer) {
        this.agreedToDisclaimer = JSON.parse(disclaimer)
      }
    },
    async saveDisclaimer() {
      this.agreedToDisclaimer = true
      localStorage.setItem('disclaimer', true)
    },
    season: {},
    async getSeason() {
      try {
        let resp = await axios({
          url: '/api/season',
        })
        this.season.start = moment(resp.data.season.start)
        this.season.end = moment(resp.data.season.end)
        this.season.claims = resp.data.season.claims_free + resp.data.season.claims_paid
        this.season.upgrades = resp.data.season.upgrades
        this.season.rewards = (parseInt(resp.data.season.revenue) / 1000000) * 0.4
      } catch (error) {
        this.log(error)
      }
    },
    claimMeta: {
      periodStart: null,
      periodLen: null,
      claimingPrice: null,
      maxClaimsPerPeriod: null,
      maxFreeClaims: null,
      maxPaidClaims: null,
      whitelistEnabled: null,
      totalClaims: null,
      claimers: null,
      whitelist: null,
    },
    async fetchClaimMeta() {
      try {
        const resp = await axios(
          `https://api${process.env.VUE_APP_TZKT}.tzkt.io/v1/contracts/${process.env.VUE_APP_PP_CLAIMER}/storage?path=claims`
        )
        if (!resp) {
          return
        }

        const claimersResp = await axios(
          `https://api${process.env.VUE_APP_TZKT}.tzkt.io/v1/contracts/${process.env.VUE_APP_PP_CLAIMER}/bigmaps/claimers/keys?limit=5000`
        )
        if (!claimersResp) {
          return
        }

        const claimers = claimersResp.data.map((claimer) => {
          const v = claimer.value
          v.free = parseInt(v.free)
          v.paid = parseInt(v.paid)
          return { value: v.free + v.paid, address: claimer.key }
        })

        const wlResp = await axios(
          `https://api${process.env.VUE_APP_TZKT}.tzkt.io/v1/contracts/${process.env.VUE_APP_PP_CLAIMER}/bigmaps/whitelist/keys?limit=5000`
        )
        if (!wlResp) {
          return
        }

        const whitelist = wlResp.data.map((wl) => {
          return { value: parseInt(wl.value), address: wl.key }
        })

        this.claimMeta.periodStart = resp.data.period_start
        this.claimMeta.periodLen = parseInt(resp.data.period_len)
        this.claimMeta.claimingPrice = parseInt(resp.data.price_pxl)
        this.claimMeta.maxClaimsPerPeriod = parseInt(resp.data.max_claims_per_period)
        this.claimMeta.maxFreeClaims = parseInt(resp.data.max_free)
        this.claimMeta.maxPaidClaims = parseInt(resp.data.max_paid)
        this.claimMeta.whitelistEnabled = resp.data.enable_whitelist
        this.claimMeta.totalClaims = parseInt(resp.data.claim_cnt)
        this.claimMeta.claimers = claimers
        this.claimMeta.whitelist = whitelist
      } catch (error) {
        this.log(error)
      }
    },
  },
}

export default {
  name: 'App',
  components: {
    Borders,
    Footer,
    Header,
    History,
  },
  data() {
    return {
      state: store.state,
    }
  },
  async mounted() {
    try {
      this.state.log(`Connecting to tezos at ${process.env.VUE_APP_TEZOS_RPC}`)
      this.state.tezos = new TezosToolkit(process.env.VUE_APP_TEZOS_RPC)
      this.state.tezos.addExtension(new Tzip16Module())
      this.state.tezos.addExtension(new Tzip12Module())

      const options = {
        name: 'PixelPower',
        iconUrl: 'https://www.pixelpower.xyz/logo-new.png',
        preferredNetwork: process.env.VUE_APP_TEZOS_NETWORK,
        eventHandlers: {
          [BeaconEvent.PAIR_INIT]: {
            handler: defaultEventCallbacks.PAIR_INIT,
          },
          [BeaconEvent.PAIR_SUCCESS]: {
            handler: defaultEventCallbacks.PAIR_SUCCESS,
          },
        },
      }
      this.state.wallet = new BeaconWallet(options)
      const activeAccount = await this.state.wallet.client.getActiveAccount()
      this.state.userAddress = activeAccount ? await this.state.wallet.getPKH() : null

      this.state.getPxlMod()
      await this.getGeo()
      await this.state.loadAllRecipes()
      await this.state.loadAllTokens(true)

      await this.getAliases()
      this.state.loadStats()
      this.state.getSeason()

      if (this.state.userAddress) {
        this.state.tezos.setProvider({ wallet: this.state.wallet })
        this.state.ppMain = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MAIN,
          compose(tzip16, tzip12)
        )
        this.state.ppMarket = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MARKET,
          tzip16
        )
        this.state.ppClaimer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CLAIMER,
          tzip16
        )
        this.state.ppCrafter = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CRAFTER,
          tzip16
        )
        this.state.ppPixel = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_PIXEL, tzip16)
        this.state.ppReferrer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_REFERRER,
          tzip16
        )
        this.state.ppDistributor = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_DISTRIBUTOR,
          tzip16
        )
        this.state.loadMyTokens(true)
        this.state.loadMyAlias()
      }
      this.subscribeToActivity()
    } catch (e) {
      console.error('Unable to init taquito', e)
      // await new Promise((resolve) => setTimeout(resolve, 15000))
      // this.$router.go(0)
    }
  },
  methods: {
    subscribeToActivity() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      })
      pusher.subscribe('activity')
      pusher.bind('activity_added', (data) => {
        const activity = data.activity.split(':')[0].replace('<', '').replace('>', '')
        console.log('Received activity ', activity)
        this.$snackbar.add({
          type: 'secondary-bg text-black',
          title: 'PixelPower Activity',
          text: activity,
        })
      })
    },
    async connectWallet() {
      try {
        await this.state.wallet.requestPermissions({
          network: {
            type: process.env.VUE_APP_TEZOS_NETWORK,
          },
        })
        this.state.userAddress = await this.state.wallet.getPKH()
        this.state.tezos.setProvider({ wallet: this.state.wallet })

        this.state.loadMyAlias()

        this.state.ppMain = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MAIN,
          compose(tzip16, tzip12)
        )
        this.state.ppMarket = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MARKET,
          tzip16
        )
        this.state.ppClaimer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CLAIMER,
          tzip16
        )
        this.state.ppCrafter = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CRAFTER,
          tzip16
        )
        this.state.ppPixel = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_PIXEL, tzip16)
        this.state.ppReferrer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_REFERRER,
          tzip16
        )
        this.state.ppDistributor = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_DISTRIBUTOR,
          tzip16
        )
        this.state.loadMyTokens(true)

        await axios({
          method: 'post',
          url: '/api/termsLog',
          data: {
            userAddress: this.state.userAddress,
            version: this.state.termsVersion,
            application: 'PixelPower',
          },
        })

        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: `${this.state.formattedAddr(
              this.state.userAddress
            )} connected their wallet! :wave:`,
          },
        })
      } catch (e) {
        console.error('Unable to connect wallet', e)
      }
    },
    async getSeriesMeta() {
      const resp = await axios({
        url: '/api/seriesMeta',
      })
      this.state.seriesMeta = resp.data.meta
      this.state.log('seriesMeta', this.state.seriesMeta)
    },
    async getAliases() {
      const resp = await axios('/api/aliases')
      this.state.aliases = resp.data.aliases
      this.state.log('aliases', this.state.aliases)
    },
    async getGeo() {
      const geoResp = await axios({
        url: '/api/geoip',
      })
      let country = geoResp?.data?.geo?.country
      const sanctions = [
        'IR',
        'IRN',
        'CU',
        'CUB',
        'SY',
        'SYR',
        'SD',
        'SDN',
        'SS',
        'SSD',
        'KP',
        'PRK',
        'UA',
        'UKR',
      ]
      this.state.canParticipate = sanctions.indexOf(country) === -1
      this.state.geoLoaded = true
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'robots',
          content: process.env.VUE_APP_ENV === 'production' ? 'index, follow' : 'noindex, nofollow',
        },
      ],
    }
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
@font-face {
  font-family: 'Hannover Messe Serif';
  src:
    local('Hannover Messe Serif'),
    url(assets/fonts/Hannover-Messe-Serif.otf) format('otf'),
    url(assets/fonts/Hannover-Messe-Serif.woff) format('woff');
}
* {
  font-family: 'Hannover Messe Serif', sans-serif;
  font-size: 20px;
}
body {
  background: #f5f5f5;
  background-image: url('assets/gun-pattern.png');
}
.speechbg {
  background-size: 100% 100%;
  background-image: url('assets/tall-speech-bub.png');
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.speechbg-rev {
  background-image: url('assets/tall-speech-bub-right.png');
  background-size: 100% 100%;
  padding-right: 2.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@screen sm {
  .speechbg {
    background-size: 100% 100%;
    background-image: url('assets/tall-speech-bub.png');
    padding-left: 3rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .speechbg-rev {
    background-image: url('assets/tall-speech-bub-right.png');
    background-size: 100% 100%;
    padding-right: 3rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@screen md {
  .speechbg {
    background-size: 100% 100%;
    background-image: url('assets/bubble.png');
    padding-left: 8rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .speechbg-rev {
    background-image: url('assets/bubble-right.png');
    background-size: 100% 100%;
    padding-right: 8rem;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 12px;
}
.multiselect__tag,
.multiselect__option--highlight {
  background: #006bb3;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #006bb3;
}
.multiselect__tag-icon:after {
  color: rgb(194, 194, 194);
  /* background: #006bb3; */
}

.slider-blue {
  --slider-connect-bg: #006bb3;
  --slider-tooltip-bg: #006bb3;
  --slider-handle-ring-color: #3b82f630;
}

.main-bg {
  background: url('assets/background-top-gradient.png'), url('assets/background.png');
  background-position:
    left top,
    right bottom;
  background-repeat: no-repeat, repeat;
}

.medal-gold {
  position: absolute;
  width: 138px;
  height: 48px;
  background: url('assets/medal-gold.png');
  top: -20px;
  left: 44%;
}

.secondary-bg {
  background: url('assets/rectangle-gradient.png') no-repeat;
  background-size: cover;
}
.help-link {
  color: #fff;
  background: #f59e0b;
}

.container-padding {
  padding: 2.5rem 5rem;
}

.tldr {
  background-color: #d7f2ff;
}

.official-link {
  background: #00aeff;
  color: #fff;
  border: 2px solid #000;
  padding: 0.8rem;
}
@media handheld, only screen and (max-width: 979px) {
  .medal-gold {
    background: url('assets/medal-gold.png') no-repeat;
    top: -20px;
    left: 39.6%;
  }
}
@media handheld, only screen and (max-width: 739px) {
  .nes-container {
    padding: 20px 15px;
  }
  .medal-gold {
    position: absolute;
    width: 138px;
    height: 48px;
    background: url('assets/medal-gold.png') no-repeat;
    background-size: 100px 40px;
    top: -20px;
    left: 35%;
  }
}
@media handheld, only screen and (max-width: 375px) {
  .medal-gold {
    position: absolute;
    width: 138px;
    height: 48px;
    background: url('assets/medal-gold.png') no-repeat;
    background-size: 100px 40px;
    top: -17px;
    left: 94px;
  }
}
@media handheld, only screen and (max-width: 325px) {
  .medal-gold {
    position: absolute;
    width: 138px;
    height: 48px;
    background: url('assets/medal-gold.png') no-repeat;
    background-size: 100px 40px;
    top: -17px;
    left: 69px;
  }
}
</style>
