<template>
  <div class="nes-container primary-bg">
    <div class="nes-container upgrade-bg container-padding">
      <div class="grid grid-cols-1 md:grid-cols-1 gap-0 items-center justify-between text-left">
        <div class="mx-auto mb-5 mt-4">
          <img src="../assets/congrats-banner.png" />
        </div>
        <div
          v-if="tokens.length"
          class="grid grid-cols-3 gap-6 align-center justify-center"
          :class="{
            'grid-cols-4': tokens.length === 2,
          }"
        >
          <div v-if="tokens.length < 3"></div>
          <div v-for="(tok, key) in tokens" :key="key" class="flex align-center justify-center">
            <router-link :to="'/token/' + tok.tokenId">
              <img class="m-auto card-glow bounce-in-top mb-2" :src="tok.metadata.artifactUri" />
            </router-link>
          </div>
          <div v-if="tokens.length < 3"></div>
        </div>
        <div v-if="tokens.length == 0" class="flex align-center justify-center text-3xl">
          Loading...
        </div>
      </div>
      <!-- <div class="nes-container lg:flex secondary-bg mt-5 capitalize text-left lg:mx-8">
        <div class="flex-initial lg:pl-10 text-center">
          You've offically got the power... but don't stop there! You've accomplished what few before you have achieved, but the fun is not over. Enjoy your
          achievement and get ready to continue your Quest to be the ONE
        </div>
      </div> -->
      <div class="flex mt-5 align-center justify-center">
        <router-link
          to="/me"
          class="text-lg text-white border-2 border-gray-700 bg-blue-normal mx-2 py-2 px-2"
        >
          Go to My Arsenal
        </router-link>
        <router-link
          to="/claim"
          class="text-lg text-white border-2 border-gray-700 bg-blue-normal mx-2 py-2 px-2"
        >
          Claim More Weapons
        </router-link>
      </div>
      <Borders />
    </div>
  </div>
</template>
<script>
import { find } from 'lodash'

import Borders from '../components/Borders'
export default {
  name: 'Congrats',
  components: {
    Borders,
  },
  data() {
    return {
      state: this.$root.$data.state,
      tokens: [],
    }
  },
  async mounted() {
    try {
      if (!this.$route.query.tokenIds) {
        this.$router.push('/')
      }
      this.getTokens()
    } catch (error) {
      console.error(error)
      this.loading = false
    }
  },
  methods: {
    async getTokens() {
      if (this.state.allTokens.length === 0) {
        this.loading = true
        await new Promise((resolve) => setTimeout(resolve, 500))
        return this.getTokens()
      }
      this.tokens.length = 0
      const ids = Array.isArray(this.$route.query.tokenIds)
        ? this.$route.query.tokenIds
        : [this.$route.query.tokenIds]
      if (ids.length === 0) {
        this.$router.push('/')
      }
      ids.map((tokenId) => {
        const t = find(this.state.allTokens, (tok) => tok.tokenId === parseInt(tokenId))
        if (t) {
          this.tokens.push(t)
        }
      })
    },
  },
}
</script>
