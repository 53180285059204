<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="nes-container modal-padding bg-white">
        <div class="modal">
          <header class="modal-header">
            <!-- <slot name="header"></slot> -->
            <button type="button" class="btn-close text-black" @click="close">X Close</button>
          </header>

          <section class="modal-body">
            <slot name="body">
              <img alt="Pixel Power" src="../assets/badge.png" class="mx-auto pb-2" width="69" />
              <p class="text-center text-sm normal-case">
                New to NFTs & crypto? Don't worry, we've got you covered!
              </p>
              <div
                class="nes-container modal-padding flex items-center secondary-bg mt-2 mb-2 text-left"
              >
                <div class="flex-none">
                  <img alt="Lightning" src="../assets/lightning.png" />
                </div>
                <div class="col-span-2 text-left pl-10 text-sm">
                  <p class="text-sm">To start collecting, having fun end earning, you'll need:</p>

                  <ul class="list-disc px-5">
                    <li class="text-base md:text-sm">
                      A Tezos (XTZ) wallet - See below how you can setup a Tezos Temple wallet.
                    </li>
                    <li class="text-base md:text-sm">
                      Acquire some XTZ from
                      <a
                        href="https://www.coinbase.com/price/tezos"
                        class="text-base md:text-sm"
                        target="_blank"
                        >Coinbase</a
                      >
                      or any other major exchange.
                    </li>
                    <li class="text-base md:text-sm">
                      Get some $PXL by exchanging your XTZ at
                      <a
                        href="https://quipuswap.com/swap/tez-KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
                        target="_blank"
                        class="text-base md:text-sm"
                        >Quipuswap</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <p class="text-center">
                We like the
                <a href="https://www.templewallet.com" target="_blank" class="text-blue">
                  Temple wallet
                </a>
                <br />
                <span class="text-center">
                  Here's a video on how to easily set up your wallet on a Chrome browser:
                </span>
              </p>
              <div class="video-container hidden lg:flex">
                <iframe
                  class="m-auto"
                  src="https://www.youtube-nocookie.com/embed/9Qqy6HX-x60"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </slot>
          </section>

          <footer class="modal-footer">
            <button
              type="button"
              class="text-white text-sm lg:text-xl uppercase py-1 px-1 md:py-1 md:px-3 bg-white border-2 border-black is-primary bg-button"
              @click="close"
            >
              GOT IT!
            </button>
          </footer>
          <Borders />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Borders from '../components/Borders'

export default {
  name: 'HelpModal',
  components: {
    Borders,
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  height: 95vh;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  justify-content: space-between;
}

.modal-footer {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.modal-body {
  position: relative;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 35px;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.modal-padding {
  padding: 0.5rem;
}
</style>
