<template>
  <div class="nes-container primary-bg">
    <div class="grid grid-cols-1 md:grid-cols-1 gap-12 items-center justify-between text-left">
      <div class="nes-container container-padding bg-white">
        <span class="medal-gold" />

        <img alt="Pixel Power" src="../assets/badge.png" class="mx-auto pb-6 pt-12" width="69" />
        <h1 class="font-black tracking-wide text-center text-2xl leading-8 decoration-0">
          Claim A Weapon
        </h1>
        <h1
          v-if="!initialized"
          class="font-black tracking-wide text-center text-2xl leading-8 decoration-0"
        >
          Loading...
        </h1>

        <div v-if="initialized" class="grid grid-cols-12 place-content-center">
          <div class="text-center inline-block col-span-12 lg:col-span-6 lg:ml-20">
            <img
              alt="Text Top Layer"
              src="../assets/text-top-layer.png"
              class="mx-auto pb-2 pt-4"
            />
            <span class="text-lg">
              Claiming period resets {{ untilNextPeriod.fromNow() }}. <br /><br />
              {{ claimedPercent }}% of all weapons have been claimed.
            </span>
            <img
              alt="Text Top Layer"
              src="../assets/text-bottom-layer.png"
              class="mx-auto pb-4 pt-2"
            />
          </div>
          <div class="text-center inline-block col-span-12 lg:col-span-6 lg:mr-20">
            <img
              alt="Text Top Layer"
              src="../assets/text-top-layer.png"
              class="mx-auto pb-2 pt-4"
            />
            <span class="text-lg">
              Current Activity:
              <br />
              My Claims : {{ myClaims }} of {{ maxMyClaims }} claimed.
              <br />
              All Claims: {{ totalClaims }} of {{ maxClaimsPerPeriod }} claimed.
            </span>
            <img
              alt="Text Top Layer"
              src="../assets/text-bottom-layer.png"
              class="mx-auto pb-4 pt-2"
            />
          </div>
        </div>

        <Borders />
      </div>
      <div
        v-if="!whitelisted && initialized"
        class="nes-container container-padding bg-blue-lighter flex flex-col items-center justify-center"
      >
        <div v-if="!whitelisting" class="flex-grow text-lg">
          Your address must be whitelisted before you can claim, but it's super quick. Are you a
          robot? Beep boop, boop beep beep?
        </div>
        <div v-if="!whitelisting" class="flex-none align-middle">
          <vue-recaptcha
            ref="vueRecaptcha2"
            class="m-auto flex-grow"
            :sitekey="siteKey"
            size="normal"
            theme="light"
            :tabindex="0"
            @verify="recaptchaVerified"
            @expire="recaptchaExpired"
            @fail="recaptchaFailed"
          />
        </div>

        <p v-if="whitelisting" class="text-center">
          Whitelisting... Please wait for block confirmation.
        </p>

        <Borders />
      </div>
      <div
        v-if="!claimedTokens && (whitelisting || claiming)"
        class="nes-container lg:flex secondary-bg mt-5 text-left"
      >
        <div class="flex-none invisible lg:visible">
          <img alt="Lightning" src="../assets/badge.png" />
        </div>
        <div class="flex-initial pl-10 text-center">
          Enjoy this random gif while u wait. You can also go do other stuff without affecting the
          transaction.
          <img :src="nextGif" width="300" class="m-auto" />
        </div>
        <div class="flex-none invisible lg:visible">
          <img alt="Lightning" src="../assets/badge.png" />
        </div>
      </div>
      <div
        v-if="
          whitelisted &&
          totalClaims < maxClaimsPerPeriod &&
          (myClaims < maxMyClaims || claimedTokens)
        "
        class="nes-container container-padding bg-white items-center text-center"
      >
        <p v-if="claiming" class="text-blue-normal text-center is-primary">
          CLAIMING.. PLEASE WAIT FOR BLOCK CONFIRMATION.
        </p>
        <div v-if="myClaims < maxFreeClaims && !whitelisting && !claiming" class="flex mb-2 mt-4">
          <button
            class="text-base cursor-pointer md:text-xl uppercase py-4 px-6 border-2 border-gray-700 bg-blue-normal text-white h-20 w-screen"
            :disabled="state.loading || claiming"
            @click="
              () => {
                claimToken()
              }
            "
          >
            <span v-if="!claiming" class="text-base md:text-xl">Claim my free weapon</span>
          </button>
        </div>
        <div v-if="maxMyClaims - myClaims >= 5 && !whitelisting && !claiming" class="flex mb-2">
          <button
            v-if="!state.loading && !claiming"
            class="text-base cursor-pointer md:text-xl uppercase py-4 px-6 border-2 border-gray-700 bg-blue-lightest h-20 w-screen"
            @click="
              () => {
                claimToken(5)
              }
            "
          >
            Claim five weapons for {{ state.convertFromMutez(claimingPrice * 5, 0) }} $PXL
          </button>
        </div>
        <div v-if="maxMyClaims - myClaims >= 10 && !whitelisting && !claiming" class="flex mb-2">
          <button
            v-if="!state.loading && !claiming"
            class="text-base cursor-pointer md:text-xl uppercase py-4 px-6 border-2 border-gray-700 bg-blue-lightest h-20 w-screen"
            @click="
              () => {
                claimToken(10)
              }
            "
          >
            Claim 10 weapons for {{ state.convertFromMutez(claimingPrice * 10, 0) }} $PXL
          </button>
        </div>
        <div v-if="maxMyClaims - myClaims >= 20 && !whitelisting && !claiming" class="flex mb-2">
          <button
            v-if="!state.loading && !claiming"
            class="text-base cursor-pointer md:text-xl uppercase py-4 px-6 border-2 border-gray-700 bg-blue-lightest h-20 w-screen"
            @click="
              () => {
                claimToken(20)
              }
            "
          >
            Claim 20 weapons for {{ state.convertFromMutez(claimingPrice * 20, 0) }} $PXL
          </button>
        </div>
        Need more
        <a
          href="https://quipuswap.com/swap/tez-KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
          target="_blank"
          >$PXL?</a
        >
        <Borders />
      </div>
      <div
        v-if="!claiming && !claimedTokens && whitelisted"
        class="nes-container container-padding bg-blue-lightest flex items-center"
      >
        <div v-if="maxMyClaims - myClaims > 0" class="flex-grow text-base md:text-2xl text-center">
          Claim your rightful share of weapons! Each time you claim you will receive a random common
          weapon from the supply. You are limited to one free claim and up to
          {{ maxPaidClaims }} paid claims per period.
        </div>
        <div v-else class="flex-grow text-base md:text-2xl text-center md:text-center">
          Looks like you've maxed out your claims for this period!<br />Claiming period resets
          {{ untilNextPeriod.fromNow() }}.
        </div>
        <Borders />
      </div>

      <div class="nes-container container-padding bg-white items-center text-center">
        <p class="text-center text-base md:text-2xl">
          Come on over to the official Pixel Games discord to chat with other collectors, post your
          trades and get support.
        </p>
        <a
          class="self-center text-base uppercase py-4 px-6 text-white nes-btn is-primary"
          href="https://discord.gg/CbdbvwtwkM"
          target="_blank"
        >
          Go to Discord
          <img
            alt="Discord"
            src="../assets/discord.png"
            class="text-right inline-block"
            width="39"
          />
        </a>

        <Borders />
      </div>
      <!-- <div v-if="state.balance === 0" class="invisible lg:visible">
        <FundsModal v-show="isModal" @close="closeModal" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { find, findIndex } from 'lodash'
import moment from 'moment'
import vueRecaptcha from 'vue3-recaptcha2'

import Borders from '../components/Borders'
// import FundsModal from '../components/FundsModal.vue'

export default {
  name: 'Claim',
  components: {
    vueRecaptcha,
    Borders,
    // FundsModal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectWallet: Function,
  },
  data() {
    return {
      state: this.$root.$data.state,
      series: this.$root.$data.state.stats ? this.$root.$data.state.stats.series : [],
      myClaims: 0,
      periodStart: new Date().toISOString(),
      periodLen: 300,
      maxFreeClaims: 1,
      maxPaidClaims: 5,
      claimingPrice: 125000,
      maxClaimsPerPeriod: 0,
      totalClaims: 0,
      claiming: false,
      confirmation: false,
      error: false,
      nextGif: null,
      getLoop: true,
      showHelp: true,
      untilNextPeriod: moment(),
      initialized: false,
      claimedTokens: null,
      whitelisted: false,
      whitelisting: false,
      whitelistEnabled: false,
      claimedPercent: 0,
      isModal: false,
      success: false,
      refLoading: false,
      refStatus: {
        isLoaded: false,
        bal: 0,
        qual: false,
        referredBy: false,
      },
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    }
  },
  computed: {
    claimsMaxed() {
      return false
    },
    batchTotal() {
      let paidClaims = this.maxPaidClaims + this.maxFreeClaims - this.myClaims
      if (this.myClaims === 0) {
        paidClaims--
      }
      return this.state.convertFromMutez(paidClaims * this.claimingPrice)
    },
    maxMyClaims() {
      return this.maxFreeClaims + this.maxPaidClaims
    },
  },
  async mounted() {
    this.state.log('claim mounted')
    this.isModal = this.state.balance === 0 ? true : false
    if (this.state.userAddress === null) {
      this.checkWallet()
      this.checkUser()
    } else {
      const sh = localStorage.getItem('showClaimHelp')
      this.showHelp = sh ? JSON.parse(sh) : true
      this.getLoop = true
      this.getClaimStorage()
      this.getReferralStatus()
      if (this.state.stats) {
        const minted = this.state.stats.series.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.tokens[0].minted
        }, 0)
        this.claimedPercent = ((minted / (15000 * this.state.stats.series.length)) * 100).toFixed(2)
      }
    }
  },
  async unmounted() {
    this.state.log('claim unmounted')
    this.getLoop = false
  },
  methods: {
    closeModal() {
      this.isModal = false
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showClaimHelp', JSON.stringify(false))
    },
    async getReferralStatus() {
      try {
        this.refLoading = true
        const resp = await axios({
          url: '/api/referralStatus',
          params: {
            userAddress: this.state.userAddress,
          },
        })
        this.refStatus.bal = resp.data.bal
        this.refStatus.qual = resp.data.qual
        this.refStatus.referredBy = resp.data.referredBy
        this.refStatus.isLoaded = true
        this.refLoading = false
      } catch (e) {
        console.error(e)
        this.refLoading = false
        await new Promise((resolve) => setTimeout(resolve, 5000))
        this.getReferralStatus()
      }
    },
    async recaptchaVerified(captchaResp) {
      try {
        this.claimedTokens = null
        this.whitelisting = true
        this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
        await axios({
          url: '/api/whitelist',
          method: 'POST',
          data: {
            address: this.state.userAddress,
            captcha: captchaResp,
          },
        })
      } catch (e) {
        this.whitelisting = false
      }
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha2.reset()
    },
    recaptchaFailed() {
      this.state.log('captcha failed')
    },
    async getClaimStorage() {
      if (!this.getLoop) {
        return
      }
      if (this.claiming) {
        await new Promise((resolve) => setTimeout(resolve, 15000))
        await this.getClaimStorage()
      }

      this.state.log('getClaimStorage')

      await this.state.fetchClaimMeta()

      this.periodStart = this.state.claimMeta.periodStart
      this.periodLen = this.state.claimMeta.periodLen
      this.claimingPrice = this.state.claimMeta.claimingPrice
      this.maxClaimsPerPeriod = this.state.claimMeta.maxClaimsPerPeriod
      this.maxFreeClaims = this.state.claimMeta.maxFreeClaims
      this.maxPaidClaims = this.state.claimMeta.maxPaidClaims
      this.whitelistEnabled = this.state.claimMeta.whitelistEnabled

      this.untilNextPeriod = moment(this.periodStart).add(this.periodLen, 'seconds')

      const wl = find(this.state.claimMeta.whitelist, (cl) => {
        return cl.address === this.state.userAddress
      })
      if (wl) {
        this.whitelisted = true
        this.whitelisting = false
      } else {
        this.whitelisted = false
      }
      const isNextPeriod = moment(this.periodStart)
        .add(this.periodLen, 'seconds')
        .isBefore(moment())
      if (!isNextPeriod) {
        const mc = find(this.state.claimMeta.claimers, (cl) => {
          return cl.address === this.state.userAddress
        })
        this.myClaims = mc ? mc.value : 0
        this.totalClaims = this.state.claimMeta.totalClaims
      } else {
        this.myClaims = 0
        this.totalClaims = 0
        this.untilNextPeriod = moment().add(this.periodLen, 'seconds')
      }
      this.initialized = true
      this.state.log(this.$data)
      // this.state.loading = false
      // this.state.log(this.$data)
      await new Promise((resolve) => setTimeout(resolve, 15000))
      await this.getClaimStorage()
    },
    async claimToken(paidAmount = 0) {
      this.state.loading = true
      this.claimedTokens = null
      this.error = false
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.claiming = true
      this.confirmation = false
      if (this.state.userAddress === null) {
        await this.state.connectWallet()
      }
      try {
        let op = null
        const opts = {}

        const transactions = []

        const powerReferrer = localStorage.getItem('powerReferrer')
        if (powerReferrer && this.refStatus.referredBy === false) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppReferrer.methods.set_referred_by(powerReferrer).toTransferParams(),
          })
        }

        const operators = [
          {
            add_operator: {
              owner: this.state.userAddress,
              operator: process.env.VUE_APP_PP_CLAIMER,
              token_id: 0,
            },
          },
        ]
        transactions.push({
          kind: OpKind.TRANSACTION,
          ...this.state.ppPixel.methods.update_operators(operators).toTransferParams(),
        })

        if (this.myClaims === 0) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppClaimer.methods.claim_free(null).toTransferParams(),
          })
        }

        for (let i = 0; i < paidAmount; i++) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppClaimer.methods.claim_paid(null).toTransferParams(),
            ...opts,
          })
        }
        const batch = await this.state.tezos.wallet.batch(transactions)
        op = await batch.send()

        await op.confirmation(1)
        const claimHash = op.opHash

        let done = false
        while (!done) {
          let claimedResp = await axios({
            url: '/api/getClaimedToken',
            params: {
              hash: claimHash,
            },
          })
          if (claimedResp.data.error) {
            // display the error message.
            this.error = true
            done = true
          } else if (claimedResp.data.claimed) {
            this.confirmation = true
            // let tknSmry = ''
            await Promise.all(
              claimedResp.data.claimed.map(async (tokenId) => {
                let claimedToken = find(this.state.allTokens, (tok) => {
                  return tok.tokenId === tokenId
                })
                if (claimedToken) {
                  if (this.claimedTokens === null) {
                    this.claimedTokens = []
                  }
                  this.claimedTokens.push(claimedToken)
                  const idx = findIndex(this.state.myTokens, (mt) => mt.tokenId === tokenId)
                  if (idx > -1) {
                    this.state.myTokens[idx].balance += 1
                  } else {
                    this.state.myTokens.push({ ...claimedToken, balance: 1, upgrading: false })
                  }
                  this.state.sortTokens(this.state.sortBy, true)

                  // tknSmry = `${tknSmry}${claimedToken.metadata.name}`
                }
              })
            )
            const usrName = this.state.userAlias
              ? this.state.userAlias
              : this.state.formattedAddr(this.state.userAddress)
            await axios({
              method: 'post',
              url: '/api/sendActivity',
              data: {
                content: `${usrName} claimed ${claimedResp.data.claimed.length} weapon${
                  claimedResp.data.claimed.length > 1 ? 's' : ''
                }! <:powerlogo:965687207182614638>`,
              },
            })
            done = true
          } else {
            await new Promise((resolve) => setTimeout(resolve, 5000))
          }
        }

        this.myClaims += this.claimedTokens.length
        this.claiming = false
        this.state.loading = false
        this.state.loadBalance()
        setTimeout(() => {
          this.state.loadMyTokens(true, true)
        }, 5000)
        this.$router.push({
          path: '/congrats',
          query: {
            tokenIds: this.claimedTokens.map((ct) => ct.tokenId),
          },
        })
      } catch (e) {
        this.state.loading = false
        this.claiming = false
        console.error('Unable to claim token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
    async checkWallet() {
      await this.connectWallet()
      if (this.state.userAddress === null) {
        this.$router.push('/')
      } else {
        this.$router.push('/claim')
      }
    },
  },
}
</script>
