// DISCLAIMER/TERMS/PRIVACY LINKS

<template>
  <div class="flex flex-col md:flex-row item-center justify-center pt-5 pb-5">
    <router-link class="px-2 text-sm font-medium text-black hover:text-gray-800" to="/disclaimer"
      >Disclaimer</router-link
    >
    <router-link class="px-2 text-sm font-medium text-black hover:text-gray-800" to="/terms"
      >Terms & Conditions</router-link
    >
    <router-link class="px-2 text-sm font-medium text-black hover:text-gray-800" to="/privacy"
      >Privacy Policy</router-link
    >
  </div>
</template>

<script>
export default {
  name: 'BottomLinks',
}
</script>
