<template>
  <div class="nes-container primary-bg">
    <div class="nes-container bg-white">
      <div class="grid grid-cols-1 items-left justify-between mt-12 mb-12">
        <h2 class="text-2xl pb-5">Terms & Conditions</h2>
        <div class="text-left w-full lg:w-11/12 m-auto">
          <pre>
1. Acceptance of Terms
These Terms and Conditions (these “Terms”) form a legal agreement between you and Pixel Global, Ltd. and all of its respective subsidiaries, affiliates, and contractors, (together and hereafter the “Company”). By using any of the Company’s services, content, or site, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with these Terms, do not use any of the Company’s services, content, or site.

2. Eligibility
You must be of the age of majority in your jurisdiction of residence or older to be eligible to use the site or request any services. By using the site, you represent and warrant that you (a) are of the age of majority in your jurisdiction of residence or older, (b) have the right, authority, and capacity to agree to and abide by these Terms, and (c) have not been previously banned or removed from the site or will participate in any activity that may result in a ban or removal from the site.

3. Access and Use of the Company’s Services and Content
Subject to your compliance with these Terms, the Company grants you access to the site and use of any services offered only for lawful purposes. The site, services, and all content and other materials available through the Company are proprietary property of the Company and are protected by British Virgin Islands and international copyright, patent, and trademark laws.

You shall not (a) sell, resell, transfer, or use commercially any of the services or content, (b) distribute or communicate (including by telecommunication), publicly display, or publicly perform any content, (c) alter or make any derivative uses of the content or any portion thereof, (d) modify, adapt, or hack the site, or otherwise attempt to gain unauthorized access to the Company related systems or networks, (e) use the content other than for their intended purposes, or (f) use any data mining, screen scraping, or similar data collection or extraction methods. Any use of the content other than as explicitly approved herein, without receiving the Company’s written permission, is strictly prohibited and will automatically terminate your authorized use of the site and any offered services. Nothing in these Terms shall be interpreted as conferring any license or right to any copyright, patent, trademark, or other proprietary rights of the Company or any third party.

4. Registration and Account Standards
By creating an account, signing up to the waiting list, or for any informational material, you agree to (a) give accurate, current, and complete information about yourself and (b) maintain your account information and update it when necessary.

5. Permission to Contact You
By providing us with your information, you agree to allow the Company to contact you via the site, text, SMS, social media, and email as applicable.

6. Lawful Basis for Collecting and Processing Data
The Company must collect, use, store, and disclose your personal information in order to provide the services offered.

If you request information or services from the Company, the following personal information will be collected:
Your web browser information (type, version)
Your wallet address (when connected to a wallet),
Your IP address and your geographic location.

Personal information requirements may change at any time. The Company will give you appropriate notice when information shall be updated, or new information will be required to remain in the Company system. By providing the Company with updated personal information, you explicitly provide your consent for the Company to use that information to provide you with the information and any offered services.

The Company will disclose personal information collected to third parties in order to provide information and services you have requested. The Company has contracts with third-party processors that forbid them from using or disclosing personal information except as necessary to execute the services you may request or to fulfil legal obligations.

By using the services, you acknowledge that you have read, understood, and agree that the Company must collect, use, store, and disclose your personal information in order to provide the information and any offered services.

7. Individual Data Rights and User Privacy
Please refer to the Company’s Privacy Policy to understand how the Company collects, uses, and discloses user data and protects user privacy.

8. User Rules of Behaviour
As a condition of use of the site, you unconditionally agree to abide by these User Rules of Behaviour and not use the site for any purpose that is prohibited. You shall not:
· Take action that is unlawful, threatening, harassing, deceptive, fraudulent, invasive of another’s privacy, or is otherwise inappropriate as determined by the Company in its sole discretion
· Provide false or misleading information
· Use or endeavour to use another’s personal information
· Impersonate any person or entity
· Harvest, data mine, or attempt to collect any personal information about others, including email
· Use the information or any offered services for the purposes of infringing on privacy rights, property rights, or any other kind of rights of any person, company, or institution
· Use the content or any offered services for any commercial purposes, including but not limited to, advertising, distribution, promotion, including chain letters, junk email, or repetitive message to anyone
· Distribute, promote, or otherwise publish any kind of malicious code, viruses of any kind, or any other item or communication that may cause harm to the site or to another user’s system in any way
· Reverse-engineer any aspect of the site or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any content
· Attempt to circumvent any content-filtering techniques the site employs or attempt to access any feature or area of the site that you are not authorized to access
· Use any sort of automated means or interface not authorized to access the site, extract data, or otherwise interfere with or modify the rendering of site pages or functionality

9. Optional PixelPower Referral Program (PRP) Rules
“Referral Rewards” will be allocated in real time to a Referral Rewards balance that is presented to the PixelPower Referrer on the PixelPower site (as long as the PixelPower Referrer’s wallet is properly connected).  The PixelPower Referrer may periodically claim and transfer $PXL directly into the PixelPower Referrer’s wallet as frequently as they like.  By participating as a PixelPower Referrer, you are agreeing to the specific set of rules (“Rules”) outlined in this section.

Each participant will be given their own link that they can share with others.  The specifics of the payout schedule along with the hierarchy of referrals will forever be stored on-chain.  Any payments to PixelPower Referrer balances will happen automatically and in real time per the configuration/development of the smart contracts associated with the collection and will not only be available for third-party analysis, but will forever be unalterable and permanent.  As new participants are referred by existing PixelPower Referrers the referral hierarchy will continue to grow but will forever be locked in on-chain.

Any associated payments of PixelPower Referrer fees will only be performed when a fee is incurred for a claim or upgrade on the PixelPower platform OR when such claims or upgrades are performed by interaction with the PixelPower smart contracts governing PixelPower.

By accepting and sharing your referral link or code or by signing up with a referral link or code, you are agreeing to be bound by these Rules.  

· 9.1. Who is eligible to be a PixelPower Referrer?
Anyone may be a PixelPower Referrer who (a) is a legal resident of his/her country and (b) is of legal age where they live.

· 9.2. Who is eligible to be a referral?
Your friends, family, and other people you know (but not yourself) are eligible to be referrals. To receive a Referral Reward for referring someone who performs a claim or upgrade, the claim or upgrade must be performed on the PixelPower platform.

· 9.3. How can I use my referral link or code?
Company wants you to share your referral link or code and earn Referral Rewards, but referral links or codes must be used only for personal and non-commercial purposes. This means that you can share your referral link or code only with people you know. You agree that you will not:
a.	Duplicate, sell, or transfer your referral link or code in any manner or make it available to the general public (such as by printing it on business cards or using paid social media or paid search);
b.	Try to get referrals by spamming, bulk emailing, or sending large numbers of unsolicited emails. The only people you should be contacting are people you know personally;
c.	Use, display, or manipulate Company intellectual property (such as Company's logos, trademarks, and copyright-protected works) in any way, except as to identify yourself as a participant, PixelPower Referrer, or PixelPower Referrer for Company;
d.	Create or register any (i) businesses, (ii) URLs, (iii) domain names, (iv) software application names or titles, or (v) social media handles or profiles that include the word "PixelPower" or any of Company's other trademarks or any words that are confusingly similar to Company's trademarks.
e.	Use Company's trademarks as your social media profile picture or wallpaper or use any of Company's copyright-protected works (such as graphics, photos, images, drawings, and screenshots from Company's website or app) without Company's express written permission;
f.	Purchase keywords (including, but not limited to Google AdWords) that contain any of Company's trademarks;
g.	Use automated systems or bots through any channel to distribute, post, or otherwise share your referral link or code;
h.	Use scripts or programmed or automatic dialers to send invites or otherwise share your referral link or code;
i.	Make misleading claims about Company, use offensive/abusive content, create fake websites/webpages/social media profiles/apps, misrepresent your connection to Company, or otherwise make any false or misleading statements to get a referral to use your link or code; or
j.	Use your referral link or code in any manner that violates the law or the rights of anyone else.
Remember, when you share your referral link or code, you should explain that you are a PixelPower participant.

· 9.4. How much do I earn for my referrals as a PixelPower Referrer?
The claiming or upgrading price (in $PXL) is presented on the PixelPower website.  The PixelPower Referrer will earn an amount equal to 10% of their referrals' claims and upgrade fees.

If a PixelPower Referrer (who has already obtained a referral code) also performs at least one upgrade or claim, additional referral levels will become open to them and they will earn additional Referral Rewards for claims and upgrades by their referrals.  The PixelPower Referrer will earn an amount equal to 3% of the claims and upgrade fees when their level two referrals (i.e., referrals that the PixelPower Referrer’s personal referrals refer) perform claims and upgrades.  In addition, the PixelPower Referrer will earn an amount equal to 2% of the claims and upgrade fees when their level three referrals (i.e., referrals that the PixelPower Referrer’s level 2 referrals refer) perform claims and upgrades.  The PixelPower Referrer’s “Referral Group” constitutes all referrals (whether level one, two or three) that the PixelPower Referrer is eligible to earn Referral Rewards on.

Example:  If an upgrade is performed and the upgrade fee happens to be listed at 850 $PXL, the Referral Rewards would be paid as follows:

-	PixelPower Referrer #1 who referred the person who performed the upgrade:		85 $PXL (10% of the upgrade fee)
-	PixelPower Referrer #2 who referred PixelPower Referrer #1:		25.5 $PXL (3% of the upgrade fee)
-	PixelPower Referrer #3 who referred PixelPower Referrer #2:		17 $PXL (2% of the upgrade fee)

· 9.5. How do I earn my Referral Reward as a PixelPower Referrer?
As long as you and your referrals follow these Rules and these Terms your Referral Rewards will begin to accumulate as upgrades and claims are made by those in your Referral Group.  Your Referral Reward balance will be presented on the PixelPower site (as long as your wallet is properly connected).  You must “Claim” that balance periodically and transfer the amount in $PXL to your connected wallet.  Note that unless stated otherwise, all Referral Rewards balances will expire 365 days from the date they are deposited.  In addition, you are solely responsible for determining what, if any, taxes apply to any transactions that are performed as a result of participation in the PRP. Company is not responsible for determining the taxes that may or may not apply to your Referral Rewards.

· 9.6. Termination and changes
Company reserves the right to change, end, or pause, in whole or in part, any PixelPower Referrer’s participation, whether currently or in the future, as well as any PixelPower Referrer's or referral's ability to participate in the PRP or receive Referral Rewards at any time for any reason, including suspected fraud (including by either the PixelPower Referrer and/or referral), abuse, or any violation of these Rules as well as any agreement (if one exists) between any PixelPower Referrer and Company or any of its subsidiaries and affiliates. 

Company may update these Rules at any time and will post the update on the PixelPower website. Continued participation in the PRP after any update will mean that you have agreed to the update.

10. Disclaimer of Warranties
THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND THE COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF ACCURACY, MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT THE COMPANY DOES NOT WARRANT THAT THE INFORMATION OR ANY OFFERED SERVICES WILL MEET YOUR REQUIREMENTS, WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, OR VIRUS-FREE, THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE INFORMATION OR ANY OFFERED SERVICE WILL BE ACCURATE OR RELIABLE, OR THAT ANY ERRORS IN THE CONTENT OR ANY OFFERED SERVICES WILL BE CORRECTED.

11.Limitation of Liability
UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE) SHALL THE COMPANY AND ITS PARENTS, SUBSIDIARIES, AFFILIATES, CONTRACTORS, LICENSORS, OWNERS, AGENTS, INFORMATION PROVIDERS, LICENSEES, DIRECTORS, OFFICERS, AND EMPLOYEES (COLLECTIVELY, THE “COMPANY PARTIES”) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, LOSS OF INCOME OR PROFITS, LOSS OF REVENUE, LOSS OF DATA OR CONTENT, DAMAGES FOR BUSINESS INTERRUPTION, OR LOSS OR DECREASE IN VALUE OF ASSETS OR SECURITIES ARISING OUT OF ACCESS TO, USE OF, OR INABILITY TO USE THE COMPANY SITE, ANY INFORMATION CONTAINED ON THE SITE, ANY OFFERED SERVICES, OR OUT OF THE BREACH OF ANY WARRANTY, OR CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, ERROR OF INFORMATION, INCORRECT INFORMATION, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OF TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT, OR DESTRUCTION OR UNAUTHORIZED ACCESS TO THE SITE.
THE LIMITATION ON THE COMPANY PARTIES’ LIABILITY ABOVE SHALL APPLY WHETHER OR NOT THE COMPANY PARTIES HAVE OR HAD BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING. IN ANY EVENT, UNDER NO CIRCUMSTANCE SHALL THE COMPANY OR ANY COMPANY PARTY BE LIABLE FOR ANY LOSS, COST, EXPENSE, OR DAMAGE TO YOU OR ANY THIRD PARTY THAT EXCEEDS THE AMOUNT ACTUALLY PAID BY YOU TO THE COMPANY OR ANY ENTITY IN CONNECTION WITH THE EVENT GIVING RISE TO SUCH LIABILITY.
Some jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the limitation may not apply to you. IN THESE JURISDICTIONS, THE COMPANY PARTIES’ LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.

12. Indemnification
Your use of the site and any offered services constitutes your express agreement to indemnify, defend, and hold harmless the Company and the Company Parties as listed under “Limitation of Liability” above (collectively, the “Indemnified Parties”) from and against any and all claims, complaints, demands, actions, suits, and proceedings by any person, including any regulatory authority (each a “third party claim/proceeding”) and all resulting liabilities and obligations (including damages, administrative monetary penalties, financial sanctions, settlement payments, expenses and costs, including lawyer’s fees) arising from, connected with, or relating to any of the following: (a) the use by or on behalf of you of any offered services, (b) your reliance on any information contained in the site, or (c) any negligence, misconduct, or breach of this Agreement, or any actual or alleged violation of any applicable law or infringement of any rights, by or on behalf of you or any other person for whom you are responsible under this agreement or at law. The Company retains the right to participate (with counsel of their own selection at their sole cost and expense) in the defence of, and settlement negotiations relating to, any third party claim/proceeding.

13. Modification of these Terms
The Company reserves the right, at any time, to make changes or modifications to these Terms. Such changes will become effective upon its publication on the Company site. If you continue to use the site or any offered services after the date which it has been changed and published, the continued use shall be deemed to constitute acceptance of any such changes. You agree to review these Terms periodically to be aware of any such revisions.

14. Third-Party Services
The Company will never sell, rent, distribute, or disclose any of your personal information to any third parties, except in an effort to provide you with services you have requested. For further information on third-party services providers, please refer to our Privacy Policy. From time to time, the Company may provide you with access to third-party websites, networks, software, databases, applications, products, systems, servers, or services (collectively referred to as “Third-Party Websites”). The Company has no control over Third-Party Websites, and the Company makes no representations or warranties of any kind concerning such Third-Party Websites, including, without limitation, concerning their completeness and accuracy.

You acknowledge and agree that your interactions with Third-Party Websites are solely between you and such websites. The Company is not responsible or liable in any matter for such interactions.

15.Governing Law
These Terms and Conditions shall be governed by the laws of the British Virgin Islands without regard to its conflicts of law principles. You hereby agree that any and all disputes and causes of action arising out of or connected with the services shall be governed by the substantive laws of the British Virgin Islands.

16. Severability
If any term, clause or provision in these Terms is held invalid or unenforceable, then it will be severable from these terms and will not affect the validity or enforceability of any remaining part of that term, clause, or provision or any other term, clause, or provision contained in these terms.

17. Termination
These Terms are effective unless and until terminated by either you or the Company. You may terminate these Terms at any time by discontinuing your use of the site and related services. The Company reserves the right, in its sole discretion and without notice, to terminate or suspend your right to use the site or any offered services, at any time and for any or no reason. You acknowledge and agree that the Company shall have no liability or obligation to you in such event.

18. Entire Agreement
These Terms constitute the entire agreement between you and the Company with respect to your access and use of the site and any offered services. These Terms or any rights and licenses granted in them, may not be transferred or assigned by you without the prior written consent of the Company. Any failure on the Company’s part to exercise or enforce any part of these Terms shall not constitute a waiver of any portion of these Terms, and the Company reserves the right to enforce any part of these Terms at any time.

These Terms and Conditions were updated on April 19, 2022.
          </pre>
        </div>
      </div>
      <Borders />
    </div>
  </div>
</template>
<script>
import Borders from '../components/Borders'
export default {
  name: 'Terms',
  project: 'PixelPower',
  version: '202204190841',
  components: {
    Borders,
  },
}
</script>
