<template>
  <div class="flex w-full h-14 justify-between header-overflow">
    <div class="flex-none z-20 relative relative logo">
      <router-link to="/">
        <img class="logo-height" src="@/assets/logo-new.png" height="80px" />
      </router-link>
    </div>
    <div class="flex items-center">
      <div class="flex-end md:ml-4">
        <button
          v-if="!state.userAddress"
          id="show-modal"
          class="hidden md:block text-black text-base lg:text-xl uppercase py-1 px-3 bg-white border-2 border-black is-primary"
          @click="showTezosModal"
        >
          Don't have a Tezos wallet?
        </button>
        <HelpModal v-show="isModalVisible" @close="closeTezosModal" />
      </div>
      <div class="flex-end md:ml-4">
        <button
          v-if="!state.userAddress"
          class="text-white text-sm lg:text-xl uppercase py-1 px-1 md:py-1 md:px-3 bg-white border-2 border-black is-primary bg-button"
          @click="showWalletModal"
        >
          Connect Wallet
        </button>
        <WalletModal
          v-show="isWalletModalVisible && !state.userAddress"
          :connect-wallet="connectWallet"
          @close="closeWalletModal"
        />
      </div>
    </div>
    <p v-if="state.userAddress" class="text-xs md:inline-block breakwords mt-0 md:mt-3">
      <AddrIcon
        :address="state.userAddress"
        :alias="state.userAlias"
        :show-disconnect="true"
        :is-pill="true"
        :link-to-collection="true"
      />
      <span class="ml-5"
        >[ {{ state.convertFromMutez(state.pxlBalance, 0) }}
        <a
          href="https://quipuswap.com/swap/tez-KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
          target="_blank"
          >$PXL</a
        >
        ]</span
      >
    </p>
  </div>
</template>

<script>
import AddrIcon from './AddrIcon.vue'
import HelpModal from './HelpModal.vue'
import WalletModal from './WalletModal.vue'

export default {
  name: 'Crest',
  components: {
    AddrIcon,
    HelpModal,
    WalletModal,
  },
  props: {
    state: {
      type: Object,
      default: () => {
        return {}
      },
    },
    connectWallet: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      isModalVisible: false,
      isWalletModalVisible: false,
    }
  },
  methods: {
    async disconnect() {
      try {
        await this.state.disconnect()
        this.$router.push('/')
      } catch (e) {
        console.error('Unable to disconnect wallet', e)
      }
    },
    showTezosModal() {
      this.isModalVisible = true
    },
    closeTezosModal() {
      this.isModalVisible = false
    },
    showWalletModal() {
      this.isWalletModalVisible = true
    },
    closeWalletModal() {
      this.isWalletModalVisible = false
    },
  },
}
</script>

<style>
.logo {
  top: 50px;
  left: 5px;
}
.logo-height {
  height: 140px;
}
@media handheld, only screen and (max-width: 1024px) {
  .logo {
    top: 65px;
    left: 20px;
  }
  .logo-height {
    height: 110px;
  }
}
@media handheld, only screen and (max-width: 739px) {
  .logo {
    top: 60px;
    left: 20px;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
