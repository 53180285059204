<template>
  <transition :duration="300">
    <section
      v-show="isOpen"
      class="fixed inset-0 overflow-hidden z-50"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="absolute inset-0 overflow-hidden">
        <transition
          enter-active-class="ease-in-out duration-500"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in-out duration-500"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-show="isOpen"
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
            @click="toggleOpen"
          />
        </transition>

        <div class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
          <transition
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
          >
            <div v-show="isOpen" class="relative w-screen max-w-md">
              <transition
                enter-active-class="ease-in-out duration-500"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in-out duration-500"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-show="isOpen"
                  class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                >
                  <button
                    class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    @click="toggleOpen"
                  >
                    <span class="sr-only">Close panel</span>
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </transition>

              <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                <div class="px-4 sm:px-6">
                  <h2 id="slide-over-title" class="text-lg font-medium text-gray-900">History</h2>
                </div>
                <div class="mt-6 relative flex-1 px-2 sm:px-2 text-sm pb-12">
                  <div class="absolute inset-0 px-2 sm:px-2">
                    <div
                      v-for="(item, key) in state.history.items"
                      :key="key"
                      class="text-left divide-y"
                    >
                      <div class="flex flex-col items-left border-dashed border-2 p-2">
                        <span class="text-xs text-gray-500">{{ item.timestamp.fromNow() }}</span>
                        <div class="">
                          <span class="">
                            {{ item.action }}
                          </span>
                          <a :href="`https://tzkt.io/${item.hash}`" target="_blank"
                            ><fa icon="external-link-alt" class="ml-1 align-baseline"
                          /></a>
                        </div>
                      </div>
                    </div>
                    <button
                      class="text-sm uppercase w-full py-4 px-6 nes-btn is-primary"
                      :disabled="loading"
                      @click="loadMore"
                    >
                      <span v-if="loading">
                        <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
                        Loading more...
                      </span>
                      <span v-if="!loading">Load more</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
// import { find,findIndex,map } from 'lodash'

/* eslint-disable vue/require-default-prop */

export default {
  name: 'History',
  components: {},
  props: {
    isOpen: Boolean,
    toggleOpen: Function,
  },
  data() {
    return {
      state: this.$root.$data.state,
      loading: false,
    }
  },
  async mounted() {
    // await this.getHistory()
  },
  async updated() {
    if (this.isOpen) {
      this.reset()
    }
  },
  methods: {
    async reset() {
      this.state.history.offset = 0
      this.state.history.items = []
      this.getHistory()
    },
    async loadMore() {
      this.state.history.offset += this.state.history.limit
      this.getHistory()
    },
    async getHistory() {
      try {
        if (this.state.userAddress === null) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
          return this.getHistory()
        }

        this.loading = true

        const historyResp = await axios({
          url: '/api/getMyTransactions',
          params: {
            limit: this.state.history.limit,
            offset: this.state.history.offset,
            address: this.state.userAddress,
            duration: this.state.history.duration,
          },
        })
        this.state.history.items = [].concat(
          this.state.history.items,
          historyResp.data.transactions.map((t) => {
            t.timestamp = moment(t.timestamp)
            return t
          })
        )

        this.loading = false
      } catch (e) {
        this.loading = false
        console.error('Unable to load history', e)
      }
    },
  },
}
</script>
