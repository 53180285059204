export default (state) => {
  const links = [
    {
      label: 'Fire in the Hole',
      path: '/',
      wallet: false,
    },
    {
      label: 'Claim',
      path: '/claim',
      wallet: true,
    },
    {
      label: 'My Arsenal',
      path: '/me',
      wallet: true,
    },
    // {
    //   label: 'Market',
    //   path: '/market',
    //   wallet: true,
    // },
    {
      label: 'Market',
      url: 'https://objkt.com/collection/artforge-pixelpower',
      wallet: false,
    },
    {
      label: 'Stats',
      path: '/stats',
      wallet: false,
    },
    {
      label: 'Leaders',
      path: '/leaders',
      wallet: false,
    },
  ]
  return links.filter((link) => {
    if (!state.userAddress) {
      return !link.wallet
    }
    return link
  })
}
