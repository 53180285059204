<template>
  <div class="nes-container primary-bg">
    <div
      class="grid grid-cols-1 md:grid-cols-1 gap-6 items-center justify-between text-left overflow-hidden"
    >
      <div class="flex justify-center md:hidden">
        <button
          v-if="!state.userAddress"
          id="show-modal"
          class="text-black text-base md:text-xl uppercase py-1 px-3 bg-white border-2 border-black is-primary"
          @click="showTezosModal"
        >
          Don't have a Tezos wallet?
        </button>
        <HelpModal v-show="isModalVisible" @close="closeTezosModal" />
      </div>

      <div class="nes-container container-padding bg-white">
        <div class="grid grid-cols-1 md:grid-cols-3">
          <span class="medal-gold" />
          <div class="text-center">
            <img
              alt="Text Top Layer"
              src="../assets/text-top-layer.png"
              class="mx-auto pt-4 sm:pt-0 pb-2"
            />
            <span class="title"> There Can Be <br class="hidden md:block" />Only One</span>
            <img
              alt="Text Top Layer"
              src="../assets/text-bottom-layer.png"
              class="mx-auto pt-2 pb-6 md:pb-0"
            />
          </div>

          <div class="col-span-2 text-left md:mt-5 md:pl-10">
            <p class="subtitle md:ml-4 md:text-left mb-2">
              PixelPower is a High-Caliber NFT Competition
            </p>
            <ul class="list-disc pl-10 mb-0 leading-loose">
              <li class="text">50+ NFT weapons with 6 levels of rarity</li>
              <li class="text">Collectors compete to build their FirePower and earn</li>
              <li class="text">Built on the tezos blockchain</li>
            </ul>
          </div>
          <div class="col-span-2 md:col-span-3 video-container my-4">
            <iframe
              class="ma-auto"
              src="https://www.youtube-nocookie.com/embed/xoucZ81UM7g"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>

        <div
          class="nes-container landing-container box-text secondary-bg mt-4 text-center mb-4 md:md-0"
        >
          The highest level rarity is a
          <span class="box-text uppercase"> Unique NFT </span> - just like Highlander, there can be
          only one.
          <p class="box-text">
            Whoever is the first to collect and upgrade enough to create the highest level rarity of
            a weapon will be the only person in the world to own the unique token with its
            associated unique artwork.
          </p>
        </div>
        <Borders />
      </div>

      <div class="nes-container container-padding bg-white">
        <div class="grid grid-cols-1 md:grid-cols-3">
          <div class="text-center">
            <img
              alt="Text Top Layer"
              src="../assets/text-top-layer.png"
              class="mx-auto pt-0 pb-2"
            />
            <span class="title"
              >Level <br class="hidden md:block" />
              Up!</span
            >
            <img
              alt="Text Top Layer"
              src="../assets/text-bottom-layer.png"
              class="mx-auto pt-2 pb-6 md:pb-0"
            />
          </div>

          <div class="col-span-2 text-left md:pl-10">
            <p class="subtitle md:ml-4 md:text-left mb-2">
              Collect and upgrade to build your FirePower
            </p>

            <ul class="list-disc pl-10 mb-0 leading-loose">
              <li class="text">
                Collect and burn two commons
                <span v-if="state.allRecipes.length" class="text"
                  >and pay {{ state.convertFromMutez(state.allRecipes[0].req_pxl, 0) }} $PXL</span
                >
                to mint one uncommon
              </li>
              <li class="text">
                Collect and burn three uncommons
                <span v-if="state.allRecipes.length" class="text"
                  >and pay {{ state.convertFromMutez(state.allRecipes[1].req_pxl, 0) }} $PXL</span
                >
                to mint one rare
              </li>
              <li class="text">
                Collect and burn four rares
                <span v-if="state.allRecipes.length" class="text"
                  >and pay {{ state.convertFromMutez(state.allRecipes[2].req_pxl, 0) }} $PXL</span
                >
                to mint one epic
              </li>
              <li class="text">
                Collect and burn five epics
                <span v-if="state.allRecipes.length" class="text"
                  >and pay {{ state.convertFromMutez(state.allRecipes[3].req_pxl, 0) }} $PXL</span
                >
                to mint one legendary
              </li>
              <li class="text">
                Collect and burn 10 legendaries
                <span v-if="state.allRecipes.length" class="text"
                  >and pay {{ state.convertFromMutez(state.allRecipes[4].req_pxl, 0) }} $PXL</span
                >
                to mint one unique
              </li>
            </ul>
          </div>
        </div>

        <img alt="cards" src="../assets/cards.png" class="mx-auto mb-6 mt-6 hidden md:block" />
        <img
          alt="cards"
          src="../assets/cards-mobile.png"
          class="mx-auto mb-6 mt-6 block md:hidden"
        />

        <div
          class="nes-container landing-container secondary-bg text-2xl leading-8 md:text-3xl text-center"
        >
          Your total FirePower is the combined FirePower of all the weapons you own
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 pb-6">
          <div class="text-center">
            <img
              alt="Text Top Layer"
              src="../assets/text-top-layer.png"
              class="mx-auto pt-6 pb-2"
            />
            <span class="title">
              The <br class="block sm:block" />
              Strongest <br class="block sm:block" />
              Are <br class="block sm:block" />
              Rewarded
            </span>
            <img alt="Text Top Layer" src="../assets/text-bottom-layer.png" class="mx-auto pt-2" />
          </div>

          <div class="col-span-2 text-left md:pl-10 pt-6 align-middle">
            <p class="subtitle pb-4">
              50% of all PixelPower claim & upgrade fees are shared back with the community each
              season:
            </p>
            <div
              v-if="!loading && state.season.end"
              class="flex align-center justify-center text-2xl"
            >
              <Countdown
                :prefix="'Season ends in '"
                :completed-msg="'Season ended! Rewards calculation is in progress...'"
                :timestamp="state.season.end"
                :countdown-classes="'text-2xl text-black'"
              />
            </div>
            <p class="subtitle">
              🔫 20% is split among the top 10% of collectors with the highest all-time FirePower.
            </p>
            <p class="subtitle">
              🔫 20% is split among the top 10% of collectors with the most FirePower gathered
              during the current season.
            </p>
            <p class="subtitle">🔫 10% is shared back to those who refer their friends.</p>
          </div>
        </div>

        <img alt="cards fade" src="../assets/cards-fade.png" class="mx-auto mb-6 mt-6" />

        <Borders />
      </div>

      <div class="nes-container text-center container-padding bg-white">
        <img alt="Pixel Power" src="../assets/logo-new.png" class="mx-auto mb-6 logo-height" />
        <p class="subtitle mb-8">
          Each person can claim {{ state.numToText(maxFreeClaims) }} free air-dropped weapon at
          random each period. We only allow a limited number of claims per period, so it's important
          to get your claim in as early as possible so you don't miss out. The free weapon will be
          of common rarity.
        </p>
        <p class="subtitle mb-8">
          We also allow each person to purchase up to
          {{ state.numToText(maxPaidClaims) }} additional random weapons of common rarity for
          {{ state.convertFromMutez(claimingPrice, 0) }} $PXL each, limited to the available claims
          per period.
        </p>
        <p class="subtitle mb-8 bold">
          You can trade your collection on
          <a
            class="subtitle"
            href="https://objkt.com/collection/artforge-pixelpower"
            target="_blank"
            >objkt.com</a
          >!
        </p>
        <p class="subtitle mb-8">
          Got some spare cards you are not collecting? Just list your tokens in the marketplace and
          someone else from the community may take you up on it.
        </p>
        <p class="subtitle mb-8">
          If you're missing a few cards to upgrade your collection, the marketplace might be a great
          shortcut to quickly grab what you need!
        </p>
        <img alt="Pixel Power" src="../assets/upgrade.png" class="mx-auto mb-6 mt-6" />

        <Borders />
      </div>

      <div class="nes-container container-padding bg-alt">
        <div class="grid grid-cols-1 mt-4">
          <p class="text-base md:text-2xl mb-0 text-center">
            To start collecting, having fun and earning, you'll need:
          </p>
          <ul class="list-disc pl-10 mb-0">
            <li class="text-base md:text-2xl">
              A Tezos (XTZ) wallet - see below how you can set up a Tezos Temple wallet.
            </li>
            <li class="text-base md:text-2xl">
              Acquire some XTZ from
              <a
                href="https://www.coinbase.com/price/tezos"
                class="text-base md:text-2xl"
                target="_blank"
                >Coinbase</a
              >
              or any other major exchange.
            </li>
            <li class="text-base md:text-2xl">
              Get some $PXL by exchanging your XTZ at
              <a
                href="https://quipuswap.com/swap/tez-KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
                target="_blank"
                class="text-base md:text-2xl"
                >Quipuswap</a
              >
            </li>
          </ul>
          <p class="text-center text-base md:text-2xl mb-0 mt-6">
            We like the
            <a href="https://www.templewallet.com" target="_blank" class="text-blue md:text-2xl">
              Temple wallet
            </a>
          </p>
          <p class="text-base text-center md:text-2xl mb-0">
            Here's a video on how to easily set up your wallet on a Chrome browser.
          </p>
        </div>
        <div class="video-container my-4">
          <iframe
            class="ma-auto"
            src="https://www.youtube-nocookie.com/embed/9Qqy6HX-x60"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <Borders />
      </div>

      <div class="nes-container text-center container-padding bg-white capitalize">
        <div>
          <h2 class="subtitle">Official PixelPower Links</h2>

          <div class="grid grid-cols-1 md:grid-cols-1">
            <p class="text uppercase">Contract Address:</p>
            <span class="mx-auto">
              <a
                v-if="state.isMobile"
                :href="`https://better-call.dev/mainnet/${state.contract}/operations`"
                target="_blank"
                class="uppercase sm:hidden overflow-ellipsis overflow-hidden official-link"
                >{{ formattedAddr }}</a
              >
              <a
                v-else
                :href="`https://better-call.dev/mainnet/${state.contract}/operations`"
                target="_blank"
                class="uppercase overflow-ellipsis overflow-hidden official-link"
              >
                <span class="md:hidden">{{ formattedAddr }}</span>
                <span class="hidden md:inline-block">{{ state.contract }}</span>
              </a>
            </span>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-8 md:gap-6 my-5">
            <div class="md:col-start-2 md:col-span-3">
              <p class="text uppercase">Discord:</p>
              <span class="mx-auto">
                <a
                  href="https://discord.gg/CbdbvwtwkM"
                  target="_blank"
                  class="uppercase overflow-ellipsis overflow-hidden official-link"
                  >Invitation</a
                >
              </span>
            </div>
            <div class="md:col-span-3 mt-6 md:mt-0">
              <p class="text uppercase">Twitter:</p>
              <span class="mx-auto">
                <a
                  href="https://twitter.com/PixelPotus"
                  target="_blank"
                  class="uppercase overflow-ellipsis overflow-hidden official-link"
                  >@PixelPotus</a
                >
              </span>
            </div>
          </div>
        </div>
        <Borders />
      </div>
    </div>
  </div>
</template>

<script>
import Borders from '../components/Borders'
import Countdown from '../components/Countdown.vue'
import HelpModal from '../components/HelpModal.vue'

export default {
  name: 'GetStarted',
  components: {
    Borders,
    HelpModal,
    Countdown,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectWallet: Function,
  },
  data() {
    return {
      state: this.$root.$data.state,
      isModalVisible: false,
      maxFreeClaims: 1,
      maxPaidClaims: 20,
      claimingPrice: 50000000,
    }
  },
  computed: {
    formattedAddr() {
      return (
        this.state.contract.substring(0, 5) +
        '..' +
        this.state.contract.substring(this.state.contract.length - 5)
      )
    },
    recordBreakers() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.state.allTokens
        .sort((a, b) => {
          return b.high - a.high
        })
        .slice(0, 3)
    },
  },
  mounted() {
    this.getClaimStorage()
    if (this.$route.query?.ref && this.$route.query?.ref.length === 36) {
      localStorage.setItem('powerReferrer', this.$route.query?.ref)
    }
  },
  methods: {
    showTezosModal() {
      this.isModalVisible = true
    },
    closeTezosModal() {
      this.isModalVisible = false
    },
    async getClaimStorage() {
      await this.state.fetchClaimMeta()

      this.claimingPrice = this.state.claimMeta.claimingPrice
      this.maxFreeClaims = this.state.claimMeta.maxFreeClaims
      this.maxPaidClaims = this.state.claimMeta.maxPaidClaims
    },
  },
}
</script>
<style scoped>
@screen sm {
  .container-padding {
    padding: 2rem;
  }
}

@screen md {
  .container-padding {
    padding: 2rem 2rem;
  }
  .title {
    font-size: 56px !important;
    line-height: 56px !important;
  }
  .subtitle {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .text {
    font-size: 24px !important;
    line-height: 40px !important;
  }

  .box-text {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

@screen lg {
  .container-padding {
    padding: 2rem 4rem;
  }
}

.title {
  font-size: 28px;
  line-height: 28px;
}

.subtitle {
  font-size: 24px;
  line-height: 36px;
}

.text {
  font-size: 20px;
  line-height: 36px;
}

.box-text {
  font-size: 18px;
  line-height: 22px;
}

.landing-container {
  padding: 1.25rem !important;
}

secondary-bg {
  background: url('../assets/rectangle-gradient.png') no-repeat;
  background-size: cover;
}
.help-link {
  color: #fff;
  background: #f59e0b;
}

.official-link {
  background: #00aeff;
  color: #fff;
  border: 2px solid #000;
  padding: 0.8rem;
}

.refer-link {
  background: #00aeff;
  color: #fff;
  border: 2px solid #000;
  padding: 0.8rem;
}

.logo-height {
  height: 120px;
}
</style>
