<template>
  <div class="nes-container primary-bg">
    <div
      class="grid grid-cols-1 md:grid-cols-1 items-center justify-between text-left mt-6 overflow-hidden pb-12"
    >
      <div v-if="loading" class="text-center">Loading...</div>
      <div v-else-if="token !== null">
        <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-6">
          <img
            class="m-auto"
            :class="{ 'vibrate-1': upgrading, 'bounce-in-top': !upgrading }"
            :src="
              token.metadata.animatedUri ? token.metadata.animatedUri : token.metadata.artifactUri
            "
          />
          <div class="text-center nes-container bg-blue-lightest md:p-5">
            <h1 class="text-2xl text-center">
              {{ token.metadata.name }}<br />
              <span class="text-xl text-gray-600">({{ token.rarity }})</span><br />
              <span class="text-xl"
                ><a
                  class="text-xl"
                  :href="`https://objkt.com/asset/artforge-pixelpower/${token.tokenId}`"
                  target="_blank"
                  >objkt.com</a
                ></span
              >
            </h1>
            <div
              v-if="token.stats"
              class="mt-5 p-2 border-2 border-dashed border-black text-base sm:text-xl"
            >
              Global Stats<br />
              <span class="inline text-center text-xs">
                Last refresh: {{ statsTimestamp.fromNow() }}
                <button class="text-blue-link hover:text-blue-hov" @click="refresh">
                  <fa icon="sync" class="h-5 w-5 align-bottom" />
                </button>
              </span>
              <div class="grid grid-cols-2 text-gray-600">
                <div class="text-right">cap:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.cap }}
                </div>
              </div>
              <div class="grid grid-cols-2 text-gray-600">
                <div class="text-right">minted:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.minted }}
                </div>
              </div>
              <div v-if="!isUnique" class="grid grid-cols-2 text-gray-600">
                <div class="text-right">burned:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.burned }}
                </div>
              </div>
              <!-- <div class="grid grid-cols-2 text-gray-600">
                <div class="text-right">trades:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.trades ? token.stats.trades : '-' }}
                </div>
              </div>
              <div class="grid grid-cols-2 text-gray-600">
                <div class="text-right">sales:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.sales ? token.stats.sales : '-' }}
                </div>
              </div>
              <div class="grid grid-cols-2 text-gray-600">
                <div class="text-right">high:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.high ? state.convertFromMutez(token.stats.high) + 'tez' : '-' }}
                </div>
              </div>
              <div class="grid grid-cols-2 text-gray-600">
                <div class="text-right">low:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.low ? state.convertFromMutez(token.stats.low) + 'tez' : '-' }}
                </div>
              </div>
              <div class="grid grid-cols-2 text-gray-600">
                <div class="text-right">last:</div>
                <div class="text-left text-green-800">
                  {{ token.stats.last ? state.convertFromMutez(token.stats.last) + 'tez' : '-' }}
                </div>
              </div> -->
            </div>
            <div
              v-if="token.rarity !== 'unique'"
              class="mt-5 p-2 border-2 border-dashed border-black text-xl"
            >
              Upgrade Requirements
              <div class="text-gray-900">
                <img width="20" class="inline mr-2 mb-2" src="../assets/flame.png" />{{
                  token.upgradeReq
                }}
                {{ token.rarity }}s
              </div>
              <div class="squared-full text-green-800">
                <fa icon="coins" class="h-5 w-5 mr-1" />{{
                  state.convertFromMutez(token.upgradeCost, 0)
                }}<span class="text-gray-600">$PXL</span>
              </div>
              <span class="text-base text-gray-900">=1x {{ token.upgradeTo }}</span>
            </div>
            <div
              v-if="state.userAddress && !isUnique"
              class="mt-5 p-2 border-2 border-dashed border-black sm:text-xl"
            >
              <div v-if="!upgrading">
                Your Balance: <span class="text-green-800">{{ token.balance }}</span>
                <br />
                <div v-if="token.balance >= token.upgradeReq && token.rarity !== 'unique'">
                  upgrade to {{ token.upgradeTo }}?
                  <div class="grid sm:grid-cols-2 gap-6 p-4 text-gray-600">
                    <div>
                      <button
                        class="text-xs uppercase p-4 nes-btn is-primary w-full"
                        @click="upgrade(token)"
                      >
                        Upgrade One
                      </button>
                    </div>
                    <div>
                      <button
                        class="text-xs uppercase p-4 nes-btn w-full"
                        @click="upgrade(token, maxUpgrade)"
                      >
                        Upgrade {{ state.numToText(maxUpgrade) }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="upgrading">
                <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
                Upgrading... Please wait up to 1 min for block confirmation.
              </span>
            </div>
            <div
              v-if="isUnique && owners.length"
              class="mt-5 p-2 border-2 border-dashed bg-black border-white text-white sm:text-xl"
            >
              Proudly Owned By
              <router-link :to="'/collection/' + owners[0].address">
                <AddrIcon :address="owners[0].address" :alias="owners[0].alias" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center">Weapon Not Found</div>
    </div>
  </div>
</template>

<script>
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { find, findIndex } from 'lodash'
import moment from 'moment'

import AddrIcon from '../components/AddrIcon'

export default {
  name: 'PowerCard',
  components: {
    AddrIcon,
  },
  data() {
    return {
      state: this.$root.$data.state,
      tokenId: null,
      token: null,
      loading: true,
      upgrading: false,
      isUnique: false,
      owners: [],
      trades: [],
      auctions: [],
      payout: null,
      refLoading: false,
      refStatus: {
        isLoaded: false,
        bal: 0,
        qual: false,
        referredBy: false,
      },
    }
  },
  computed: {
    statsTimestamp() {
      return this.state.statsTimestamp
    },
    maxUpgrade() {
      if (!this.token) {
        return 1
      }
      return Math.floor(this.token.balance / this.token.upgradeReq)
    },
  },
  watch: {
    $route() {
      this.getId()
    },
  },
  async mounted() {
    this.state.log(this.$route.params)
    this.getId()
    this.getReferralStatus()
  },
  methods: {
    async getId() {
      try {
        if (this.$route.params.tokenId) {
          this.tokenId = parseInt(this.$route.params.tokenId)
          if (
            this.state.allTokens.length === 0 ||
            this.state.stats?.series?.length === 0 ||
            this.state?.aliases?.length === 0
          ) {
            this.loading = true
            await new Promise((resolve) => setTimeout(resolve, 500))
            return this.getId()
          }
          this.token = find(this.state.allTokens, (tok) => {
            return tok.tokenId === this.tokenId
          })

          this.token.balance = 0
          if (this.state.userAddress) {
            this.getBalance()
          }

          // if (this.tokenId > 6000) {
          //   this.isUnique = true
          //   const owners = await axios({
          //     url: '/api/tokenOwners',
          //     params: {
          //       tokenId: this.tokenId,
          //     },
          //   })
          //   if (owners.data.owners.length > 0) {
          //     this.owners = owners.data.owners.map((o) => {
          //       const alias = find(this.state.aliases, (a) => {
          //         return a.address === o.address
          //       })
          //       if (alias) {
          //         o.alias = alias.alias
          //       }
          //       return o
          //     })
          //   } else {
          //     const resp = await axios({
          //       url: '/api/trades',
          //       params: {
          //         limit: 1,
          //         filter: '' + this.tokenId,
          //         filterAccept: false,
          //       },
          //     })
          //     if (resp.data.trades.length > 0) {
          //       this.trades = resp.data.trades.map((trade) => {
          //         const alias = find(this.state.aliases, (a) => {
          //           return a.address === trade.owner
          //         })
          //         if (alias) {
          //           trade.alias = alias.alias
          //         }
          //         return trade
          //       })
          //       await this.getPayoutStatus()
          //     } else {
          //       const resp = await axios({
          //         url: '/api/auctions',
          //         params: {
          //           limit: 1,
          //           filter: '' + this.tokenId,
          //         },
          //       })
          //       if (resp.data.auctions.length > 0) {
          //         this.auctions = resp.data.auctions.map((auction) => {
          //           const alias = find(this.state.aliases, (a) => {
          //             return a.address === auction.seller
          //           })
          //           if (alias) {
          //             auction.alias = alias.alias
          //           }
          //           return auction
          //         })
          //         await this.getPayoutStatus()
          //       }
          //     }
          //   }
          // }

          const seriesId = this.tokenId % 1000
          const series = find(this.state.stats.series, (s) => {
            return s.seriesId === seriesId
          })
          if (series) {
            this.token.stats = find(series.levels, (l) => {
              return l.tokenId === this.tokenId
            })
          }
          this.state.log(this.token)
          this.loading = false
        } else {
          this.tokenId = null
          this.token = null
        }
      } catch (error) {
        this.loading = false
      }
    },
    async getReferralStatus() {
      try {
        this.refLoading = true
        const resp = await axios({
          url: '/api/referralStatus',
          params: {
            userAddress: this.state.userAddress,
          },
        })
        this.refStatus.bal = resp.data.bal
        this.refStatus.qual = resp.data.qual
        this.refStatus.referredBy = resp.data.referredBy
        this.refStatus.isLoaded = true
        this.refLoading = false
      } catch (e) {
        console.error(e)
        this.refLoading = false
        await new Promise((resolve) => setTimeout(resolve, 5000))
        this.getReferralStatus()
      }
    },
    async refresh() {
      this.loading = true
      this.token = null
      const statsResp = await axios({
        url: '/api/statsv2',
      })
      this.state.stats = statsResp.data.stats
      this.state.statsTimestamp = moment()
      localStorage.setItem(
        'stats',
        JSON.stringify({ stats: statsResp.data.stats, timestamp: new Date().toISOString() })
      )
      this.getId()
    },
    async getBalance() {
      if (this.state.myTokens.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        return this.getBalance()
      }
      this.state.log('get balance', this.state.myTokens, this.tokenId)
      const mt = find(this.state.myTokens, (tok) => {
        return tok.tokenId === this.tokenId
      })
      if (mt) {
        this.token.balance = mt.balance
      }
    },
    async upgrade(tokens, times = 1) {
      if (!Array.isArray(tokens)) {
        tokens = [tokens]
      }
      if (tokens.length == 0) {
        return
      }
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      const transactions = []

      const powerReferrer = localStorage.getItem('powerReferrer')
      if (powerReferrer && this.refStatus.referredBy === false) {
        transactions.push({
          kind: OpKind.TRANSACTION,
          ...this.state.ppReferrer.methods.set_referred_by(powerReferrer).toTransferParams(),
        })
      }

      const pixelOp = [
        {
          add_operator: {
            owner: this.state.userAddress,
            operator: process.env.VUE_APP_PP_CRAFTER,
            token_id: 0,
          },
        },
      ]
      transactions.push({
        kind: OpKind.TRANSACTION,
        ...this.state.ppPixel.methods.update_operators(pixelOp).toTransferParams(),
      })
      this.upgrading = true
      this.state.loading = true
      try {
        let op = null
        for (let i = 0; i < tokens.length; i++) {
          const token = tokens[i]
          token.upgrading = true
          const recipe = token.recipe

          const operators = recipe.req_items.map((item) => {
            return {
              add_operator: {
                owner: this.state.userAddress,
                operator: process.env.VUE_APP_PP_CRAFTER,
                token_id: item.token_id,
              },
            }
          })
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppMain.methods.update_operators(operators).toTransferParams(),
          })

          for (let i = 0; i < times; i++) {
            transactions.push({
              kind: OpKind.TRANSACTION,
              ...this.state.ppCrafter.methods.craft(recipe.id).toTransferParams(),
            })
          }
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        op = await batch.send()
        await op.confirmation(1)

        this.state.loading = false
        this.confirmation = true
        setTimeout(() => {
          this.confirmation = false
        }, 30000)

        const usrName = this.state.userAlias
          ? this.state.userAlias
          : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} upgraded ${times} weapon${times > 1 ? 's' : ''}. :fire:`
        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: action,
          },
        })

        tokens.map((token) => {
          token.balance -= token.upgradeReq
          token.upgrading = false
          if (token.balance === 0) {
            const idx = findIndex(this.myTokens, (mt) => {
              return mt.tokenId === token.tokenId
            })
            this.myTokens.splice(idx, 1)
          }
        })
        const tokenIds = tokens.map((t) => t.recipe.crafted_item.token_id)

        this.state.sortTokens(this.state.sortBy, true)
        this.$forceUpdate()
        setTimeout(() => {
          this.state.loadMyTokens(true, true)
        }, 15000)

        this.upgrading = false
        this.$router.push({
          path: '/congrats',
          query: {
            tokenIds,
          },
        })
      } catch (e) {
        tokens.map((token) => (token.upgrading = false))
        this.upgrading = false
        this.state.loading = false
        this.handleBatchStop()
        console.error('Unable to upgrade token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
  },
}
</script>
