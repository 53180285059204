<template>
  <div>
    <span class="corner TL" />
    <span class="corner TR" />
    <span class="corner BL" />
    <span class="corner BR" />
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
.corner {
  position: absolute;
  width: 90px;
  height: 70px;
}

.TL {
  background: url('../assets/corner-upper-left.png');
  top: -10px;
  left: -10px;
}
.TR {
  background: url('../assets/corner-upper-right.png');
  top: -10px;
  right: -10px;
}
.BL {
  background: url('../assets/corner-lower-left.png');
  bottom: -10px;
  left: -10px;
}
.BR {
  background: url('../assets/corner-lower-right.png');
  bottom: -10px;
  right: -10px;
}

@media handheld, only screen and (max-width: 739px) {
  .corner {
    position: absolute;
    width: 90px;
    height: 70px;
  }
  .TL {
    background: url('../assets/corner-upper-left.png') no-repeat;
    background-size: 60px 40px;
    top: -10px;
    left: -7px;
  }
  .TR {
    background: url('../assets/corner-upper-right.png') no-repeat;
    background-size: 60px 40px;
    top: -10px;
    right: -38px;
  }
  .BL {
    background: url('../assets/corner-lower-left.png') no-repeat;
    background-size: 60px 40px;
    left: -7px;
    bottom: -40px;
  }
  .BR {
    background: url('../assets/corner-lower-right.png') no-repeat;
    background-size: 60px 40px;
    right: -37px;
    bottom: -40px;
  }
}
</style>
