<template>
  <div class="nes-container primary-bg overflow-hidden">
    <div
      class="nes-container bg-white grid grid-cols-1 md:grid-cols-1 gap-6 items-center justify-between text-left"
    >
      <span class="medal-gold" />
      <img alt="PixelPower" src="../assets/badge.png" class="mx-auto pb-2 pt-6" width="69" />
      <h1 class="text-xl md:text-3xl text-center">
        Global Weapon Stats<br />
        <span v-if="!loading" class="inline text-center text-xs">
          Last refresh: {{ statsTimestamp ? statsTimestamp.fromNow() : 'never' }}
          <button v-if="statsTimestamp" class="text-blue-link hover:text-blue-hov" @click="refresh">
            <fa icon="sync" class="h-5 w-5 align-bottom" />
          </button>
        </span>
      </h1>
      <div v-if="loading" class="m-auto">Loading...</div>
      <div v-if="state.seriesMeta" class="nes-container secondary-bg">
        <h1 class="text-xl md:text-2xl text-center text-bold text-black">Upgrade Requirements</h1>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 text-center justify-between"
        >
          <div v-for="(sm, key) in state.seriesMeta" :key="key" class="text-black">
            To {{ sm.to }}
            <div class="text-base md:text-xl text-bold text-black">
              {{ sm.upgradeReq }} {{ sm.from }}
            </div>
            <div class="text-base md:text-xl text-bold squared-full text-black">
              {{ state.convertFromMu$PXL(sm.upgradeCost) }}<span class="text-black">$PXL</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="stats" class="p-5 nes-container bg-blue-lightest">
        <div class="grid grid-cols-5 items-center">
          <label
            for="filter_trades"
            class="sm:col-span-2 md:col-span-1 col-span-5 block text-xl font-medium"
          >
            Search By:
          </label>
          <input
            id="filter_trades"
            v-model="filter"
            type="text"
            name="filter_trades"
            class="sm:col-span-3 md:col-span-4 col-span-5 w-full md:w-64 relative inline-block my-2 mr-2 p-2 border-2 mt-1 focus:ring-blue-500 focus:border-blue-500 block shadow-sm border-gray-300 rounded-none"
            placeholder="Weapon Name"
            @input="updateFilters()"
          />
        </div>
        <!-- <button
          class="float-right text-blue-link hover:text-blue-hov text-xs"
          @click="clearFilters"
        >
          reset
        </button> -->
        <div class="grid grid-cols-5 items-center">
          <label class="sm:col-span-2 md:col-span-1 col-span-5 text-xl font-medium grid grid-cols-5"
            >Rarities:
          </label>
          <div
            class="sm:col-span-3 md:col-span-4 col-span-5 w-full md:w-64 relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in"
          >
            <VueMultiselect
              v-model="rarities"
              :options="raritiesOptions"
              :multiple="true"
              placeholder="Select Rarities"
              :show-labels="false"
              @select="updateFilters"
              @remove="updateFilters"
            />
          </div>
        </div>
      </div>
      <Borders />
      <div v-if="!loading" class="flex flex-col">
        <span class="sm:hidden text-xs text-center">Drag to scroll horizontally</span>
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-12">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Weapon
                    </th>
                    <th
                      v-show="rarities.length == 0 || rarities.includes('common')"
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Common
                    </th>
                    <th
                      v-show="rarities.length == 0 || rarities.includes('uncommon')"
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Uncommon
                    </th>
                    <th
                      v-show="rarities.length == 0 || rarities.includes('rare')"
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Rare
                    </th>
                    <th
                      v-show="rarities.length == 0 || rarities.includes('epic')"
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Epic
                    </th>
                    <th
                      v-show="rarities.length == 0 || rarities.includes('legendary')"
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Legendary
                    </th>
                    <th
                      v-show="rarities.length == 0 || rarities.includes('unique')"
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Unique
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(series, skey) in filteredStats" :key="skey">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex flex-col items-left">
                        <div class="text-xl text-bold font-medium text-gray-900 p-1">
                          {{ series.tokens[0].metadata.shortName }}
                        </div>
                      </div>
                    </td>
                    <td
                      v-for="(lvl, lvlkey) in series.tokens"
                      v-show="rarities.length == 0 || rarities.includes(lvl.rarity)"
                      :key="lvlkey"
                      class="px-3 py-2 whitespace-nowrap border-l-2"
                    >
                      <router-link :to="'/token/' + lvl.tokenId">
                        <div class="flex flex-row gap-2 items-center">
                          <div class="w-20">
                            <img class="w-20 square-full" :src="lvl.metadata.displayUri" alt="" />
                          </div>
                          <div class="w-24">
                            <div class="grid grid-cols-2 gap-1 text-xs text-blue-100">
                              <div class="text-right">Cap:</div>
                              <div class="text-left text-black">
                                {{ lvl.cap }}
                              </div>
                            </div>
                            <div class="grid grid-cols-2 gap-1 text-xs text-blue-600">
                              <div class="text-right">Minted:</div>
                              <div class="text-left text-black">
                                {{ lvl.minted }}
                              </div>
                            </div>
                            <div
                              v-if="lvl.rarity !== 'unique'"
                              class="grid grid-cols-2 gap-1 text-xs text-blue-600"
                            >
                              <div class="text-right">Burned:</div>
                              <div class="text-left text-black">
                                {{ lvl.burned }}
                              </div>
                            </div>
                            <div class="grid grid-cols-2 gap-1 text-xs text-blue-600">
                              <div class="text-right">Supply:</div>
                              <div class="text-left text-black">
                                {{ lvl.supply }}
                              </div>
                            </div>
                            <!-- <div class="grid grid-cols-2 gap-1 text-xs text-blue-100 w-48">
                              <div class="text-right">FirePower:</div>
                              <div class="text-left text-black">
                                {{ lvl.FirePower ? lvl.FirePower : '-' }}
                              </div>
                            </div> -->
                            <!-- <div class="grid grid-cols-2 gap-1 text-xs text-blue-600 w-48">
                              <div class="text-right">Trades:</div>
                              <div class="text-left text-black">
                                {{ lvl.trades ? lvl.trades : '-' }}
                              </div>
                            </div>
                            <div class="grid grid-cols-2 gap-1 text-xs text-blue-600 w-48">
                              <div class="text-right">Sales:</div>
                              <div class="text-left text-black">
                                {{ lvl.sales ? lvl.sales : '-' }}
                              </div>
                            </div>
                            <div class="grid grid-cols-2 gap-1 text-xs text-blue-600 w-48">
                              <div class="text-right">High:</div>
                              <div class="text-left gap-1 text-black">
                                {{ lvl.high ? state.convertFromMu$PXL(lvl.high, 2) + '$PXL' : '-' }}
                              </div>
                            </div>
                            <div class="grid grid-cols-2 gap-1 text-xs text-blue-600 w-48">
                              <div class="text-right">Low:</div>
                              <div class="text-left text-black">
                                {{ lvl.low ? state.convertFromMu$PXL(lvl.low, 2) + '$PXL' : '-' }}
                              </div>
                            </div>
                            <div class="grid grid-cols-2 gap-1 text-xs text-blue-600 w-48">
                              <div class="text-right">Last:</div>
                              <div class="text-left text-black">
                                {{ lvl.last ? state.convertFromMu$PXL(lvl.last, 2) + '$PXL' : '-' }}
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import VueMultiselect from 'vue-multiselect'

import Borders from '../components/Borders'

export default {
  name: 'Stats',
  components: {
    Borders,
    VueMultiselect,
  },
  data() {
    return {
      state: this.$root.$data.state,
      loading: true,
      showFilters: true,
      filter: '',
      rarities: [],
      raritiesOptions: ['common', 'uncommon', 'rare', 'epic', 'legendary', 'unique'],
      filteredStats: [],
      autosave: {
        filter: '',
        rarities: [],
      },
    }
  },
  computed: {
    stats() {
      return this.state.stats
    },
    statsTimestamp() {
      return this.state.statsTimestamp
    },
  },
  watch: {
    filter() {
      this.saveFilters()
    },
    rarities() {
      this.saveFilters()
    },
  },
  async mounted() {
    this.state.log('Mounted stats')

    const filters = localStorage.getItem('statsFilter')
    const autosave = JSON.parse(filters)
    if (autosave) {
      this.filter = autosave.filter
      this.rarities = autosave.rarities
    }

    this.load()
  },
  methods: {
    clearFilters() {
      this.filter = ''
      this.rarities.length = 0
      this.saveFilters()
      this.load()
    },
    saveFilters() {
      this.autosave.filter = this.filter
      this.autosave.rarities = this.rarities
      localStorage.setItem('statsFilter', JSON.stringify(this.autosave))
    },
    async load() {
      if (this.stats === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        await this.load()
      } else {
        this.state.log('Stats loaded')
        this.filteredStats = this.stats.series
        this.updateFilters()
        this.loading = false
      }
    },
    async refresh() {
      this.loading = true
      await this.state.loadAllTokens(true)
      await this.state.loadStats(true)
      this.loading = false
    },
    updateFilters() {
      this.debouncedUpdate()
    },
    debouncedUpdate: debounce(function () {
      this.offset = 0
      this.filteredStats = this.stats.series.filter((t) => {
        let sRet = true

        if (this.filter !== '' && t.title.toLowerCase().indexOf(this.filter.toLowerCase()) == -1) {
          sRet = false
        }

        return sRet
      })
    }, 50),
  },
}
</script>
