<template>
  <div class="nes-container primary-bg overflow-hidden">
    <div
      class="nes-container bg-white grid grid-cols-1 gap-6 items-center justify-between text-left"
    >
      <span class="medal-gold" />
      <img alt="Pixel Power" src="../assets/badge.png" class="mx-auto pb-2 pt-6" width="69" />
      <h1 class="text-xl md:text-3xl text-center">Leaders</h1>

      <div
        v-if="!loading && state.season.end"
        class="flex flex-col align-center justify-center text-center text-2xl"
      >
        <Countdown
          :prefix="'Season ends in '"
          :completed-msg="'Season ended! Rewards calculation is in progress...'"
          :timestamp="state.season.end"
          :countdown-classes="'text-2xl text-black'"
        />
        <div class="flex align-center justify-center text-center text-2xl">
          <div class="hwrap md:w-1/3">
            <div class="hmove">
              <div class="hitem">
                Current leader rewards:
                <span class="text-yellow-500">{{ state.season.rewards }} $PXL</span>
              </div>
              <div class="hitem">
                Claims this season: <span class="text-yellow-500">{{ state.season.claims }}</span>
              </div>
              <div class="hitem">
                Upgrades this season:
                <span class="text-yellow-500">{{ state.season.upgrades }}</span>
              </div>
              <div class="hitem">
                Total wallets:
                <span class="text-yellow-500">{{ total }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="state.userAddress && rewardsStatus.bal > 0"
        class="grid grid-cols-8 items-center nes-container secondary-bg mt-5 mb-5 md:mb-0 text-left"
      >
        <div class="col-span-1 w-14 h-14 mr-3">
          <span>
            <img alt="Lightning" src="../assets/lightning.png" />
          </span>
        </div>
        <div class="col-span-7 md:col-span-5">
          <span class="text-base md:text-2xl text-center md:text-left">
            Congratulations! You have {{ state.convertFromMutez(rewardsStatus.bal, 0) }} unclaimed
            $PXL rewards from previous seasons.
          </span>
        </div>
        <div class="col-span-8 md:col-span-2 justify-self-center md:justify-self-end w-14 h-14">
          <button
            class="py-4 px-6 border-2 border-gray-700 bg-blue-normal text-white"
            @click="claimRewardsBalance"
          >
            Claim Now
          </button>
        </div>
      </div>

      <div v-if="loading" class="text-center">Loading...</div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 text-center mb-5 md:mb-0">
        <a
          href="#"
          class="nes-btn"
          :class="{ 'is-primary': view == 'weighted' }"
          @click="changeView('weighted')"
          >All Time</a
        >
        <a
          href="#"
          class="nes-btn"
          :class="{ 'is-primary': view == 'weighted_season' }"
          @click="changeView('weighted_season')"
          >Current Season</a
        >
      </div>
      <div v-if="myRank !== -1" class="m-auto mb-5 md:mb-0 text-center nes-container secondary-bg">
        <h1 class="text-2xl">Your Rank: #{{ myRank }} of {{ total }}</h1>
        {{ percentile }}
      </div>
      <div class="flex flex-col">
        <Pagination
          :offset="offset"
          :limit="limit"
          :pages="pages"
          class="-mt-10"
          @first-page="firstPage"
          @prev-page="prevPage"
          @next-page="nextPage"
          @last-page="lastPage"
          @page-size-selected="pageSizeSelected"
        >
          <template #perPageOptions>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </template>
        </Pagination>
        <div class="hidden lg:inline-block -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 text-sm lg:text-base">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Rank
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Collector
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cards
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      FirePower (All-time)
                      <span class="inline"
                        ><Popper placement="top" :hover="false" :arrow="true" class="inline">
                          <button class="text-blue-link hover:text-blue-hov">
                            <fa icon="info-circle" class="align-middle" />
                          </button>
                          <template #content>
                            <div class="nes-container is-rounded bg-white" style="width: 44rem">
                              <img
                                alt="Pixel Power"
                                src="../assets/cards.png"
                                class="mx-auto pb-6 pt-6"
                              />
                            </div>
                          </template>
                        </Popper>
                      </span>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      FirePower (Season)
                    </th>
                    <!-- <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Trades
                    </th> -->
                    <!-- <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Barters
                    </th> -->
                    <!-- <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total Earned
                    </th> -->
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(entry, key) in leaders" :key="key">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ entry.rank }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        <AddrIcon :address="entry.id" :alias="entry.alias" />
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ entry.total }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ entry.weighted }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ entry.weighted_season }}
                      </div>
                    </td>
                    <!-- <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ entry.trades }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ entry.barters }}
                      </div>
                    </td> -->
                    <!-- <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ state.convertFromMutez(entry.sales, 0) }}$PXL
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:hidden gap-2">
          <div
            v-for="(entry, key) in leaders"
            :key="key"
            class="nes-container is-rounded"
            :class="{ 'is-dark': entry.id === state.userAddress }"
          >
            <div class="flex text-left text-xs items-center">
              <div class="flex-shrink mr-2 text-xl">#{{ entry.rank }}</div>
              <div class="flex-grow">
                <span class="text-base"
                  ><router-link :to="'/collection/' + entry.id"
                    ><AddrIcon :address="entry.id" :alias="entry.alias" /></router-link></span
                ><br />
                <span class="text-base">{{ entry.weighted }}</span> FP (All-time)<br />
                <span class="text-base">{{ entry.weighted_season }}</span> FP (Season)<br />
                <span class="text-base">{{ entry.total }}</span> Weapon<span v-if="entry.total > 1"
                  >s</span
                ><br />
                <!-- <span class="text-base">{{ entry.trades }}</span> trades<br />
                <span class="text-base">{{ entry.barters }}</span> barters<br />
                <span class="text-base">{{ state.convertFromMutez(entry.sales, 0) }}$PXL</span> in
                sales<br /> -->
              </div>
            </div>
          </div>
        </div>
        <Pagination
          :offset="offset"
          :limit="limit"
          :pages="pages"
          @first-page="firstPage"
          @prev-page="prevPage"
          @next-page="nextPage"
          @last-page="lastPage"
          @page-size-selected="pageSizeSelected"
        >
          <template #perPageOptions>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </template>
        </Pagination>
      </div>
      <Borders />
    </div>
    <div class="nes-container container-padding bg-white">
      <div
        class="nes-container landing-container secondary-bg text-2xl leading-8 md:text-3xl text-center"
      >
        Your total FirePower is the combined FirePower of all the weapons you own.
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 pb-6">
        <div class="text-center">
          <img alt="Text Top Layer" src="../assets/text-top-layer.png" class="mx-auto pt-6 pb-2" />
          <span class="title">
            The <br class="block sm:block" />
            Strongest <br class="block sm:block" />
            Are <br class="block sm:block" />
            Rewarded
          </span>
          <img alt="Text Top Layer" src="../assets/text-bottom-layer.png" class="mx-auto pt-2" />
        </div>

        <div class="col-span-2 text-left md:pl-10 pt-6 align-middle">
          <p class="subtitle pb-4">
            50% of all PixelPower claim & upgrade fees are shared back with the community each
            season:
          </p>
          <div
            v-if="!loading && state.season.end"
            class="flex align-center justify-center text-2xl"
          >
            <Countdown
              :prefix="'Season ends in '"
              :completed-msg="'Season ended! Rewards calculation is in progress...'"
              :timestamp="state.season.end"
              :countdown-classes="'text-2xl text-black'"
            />
          </div>
          <p class="subtitle">
            🔫 20% is split among the top 10% of collectors with the highest all-time FirePower.
          </p>
          <p class="subtitle">
            🔫 20% is split among the top 10% of collectors with the most FirePower gathered during
            the current season.
          </p>
          <p class="subtitle">🔫 10% is shared back to those who refer their friends.</p>
        </div>
      </div>

      <img alt="cards fade" src="../assets/cards-fade.png" class="mx-auto mb-6 mt-6" />

      <Borders />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { find } from 'lodash'
import Popper from 'vue3-popper'

import AddrIcon from '../components/AddrIcon'
import Borders from '../components/Borders'
import Countdown from '../components/Countdown.vue'
import Pagination from '../components/Pagination'

export default {
  name: 'Leaders',
  components: {
    AddrIcon,
    Borders,
    Pagination,
    Popper,
    Countdown,
  },
  data() {
    return {
      state: this.$root.$data.state,
      view: 'weighted',
      leaders: [],
      total: 0,
      myRank: -1,
      loading: false,
      showHelp: true,
      filter: '',
      offset: 0,
      limit: 20,
      showAliasInput: false,
      aliasInput: '',
      aliaserCost: 250000,
      aliaserMaxLen: 15,
      settingAlias: false,
      rewardsLoading: false,
      rewardsStatus: {
        bal: 0,
      },
    }
  },
  computed: {
    pages() {
      if (!this.leaders) {
        return 1
      }
      let tot = parseInt((this.total / this.limit).toFixed(0))
      if (tot < this.total / this.limit) {
        tot++
      }
      return tot
    },
    percentile() {
      if (this.myRank > -1 && this.total) {
        const topTen = Math.round(this.total * 0.1)
        if (this.myRank <= topTen) {
          return `You are in the top 10% of collectors this season! Keep it up, and you'll be eligible for rewards at the end of the season.`
        } else {
          return `You're close! Keep building your firepower to make it into the top 10% (Rank ${topTen} or above)`
        }
      }

      return ''
    },
  },
  async mounted() {
    const sh = localStorage.getItem('showLeadersHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    if (this.state.userAddress) {
      this.getRewardsStatus()
    }
    await this.getLeaders()
  },
  methods: {
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.total) {
        this.offset -= this.limit
      }
      this.getLeaders()
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {
        this.offset = 0
      } else {
        this.offset = offset
      }
      this.getLeaders()
    },
    firstPage() {
      this.offset = 0
      this.getLeaders()
    },
    lastPage() {
      this.offset = (this.pages - 1) * this.limit
      this.getLeaders()
    },
    async pageSizeSelected(value) {
      this.offset = 0
      this.limit = parseInt(value)
      this.getLeaders()
    },
    changeView(v) {
      this.view = v
      this.leaders = []
      this.total = 0
      this.offset = 0
      this.myRank = -1
      this.getLeaders()
    },
    async refresh() {
      await this.getLeaders()
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showLeadersHelp', JSON.stringify(false))
    },
    async getLeaders() {
      this.state.log('aliases leng', this.state.aliases.length)
      if (this.state.aliases.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        await this.getLeaders()
      }
      this.state.log('load leaders')
      this.loading = true
      try {
        let resp = await axios({
          url: '/api/leaders',
          params: {
            orderBy: this.view,
            limit: this.limit,
            offset: this.offset,
          },
        })
        this.leaders = resp.data.leaders.map((l) => {
          const alias = find(this.state.aliases, (a) => {
            return a.address == l.id
          })
          if (alias) {
            l.alias = alias.alias
          }
          return l
        })
        this.total = resp.data.total

        this.loading = false

        if (this.state.userAddress && this.myRank == -1) {
          resp = await axios({
            url: '/api/myRank',
            params: {
              orderBy: this.view,
              owner: this.state.userAddress,
            },
          })
          this.myRank = resp.data.rank
        }
      } catch (error) {
        this.loading = false
      }
    },
    async claimRewardsBalance() {
      try {
        this.rewardsLoading = true
        const bal = this.rewardsStatus.bal / 1000000
        const op = await this.state.ppDistributor.methods.claim().send()
        await op.confirmation(2)
        const usrName = this.state.userAlias
          ? this.state.userAlias
          : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} just claimed ${bal.toFixed(
          0
        )} $PXL from season rewards! :moneybag::moneybag::moneybag:`
        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: action,
          },
        })
        await this.getRewardsStatus()
      } catch (e) {
        this.rewardsLoading = false
        console.error(e)
        // alert('Error claiming rewards: ' + e?.message)
      }
    },
    async getRewardsStatus() {
      try {
        this.rewardsLoading = true
        const resp = await axios({
          url: '/api/rewardsStatus',
          params: {
            userAddress: this.state.userAddress,
          },
        })
        this.rewardsStatus.bal = resp.data.bal
        this.rewardsLoading = false
      } catch (e) {
        console.error(e)
        this.rewardsLoading = false
        await new Promise((resolve) => setTimeout(resolve, 5000))
        this.getRewardsStatus()
      }
    },
  },
}
</script>
<style scoped>
@screen sm {
  .container-padding {
    padding: 2rem;
  }
}

@screen md {
  .container-padding {
    padding: 2rem 2rem;
  }
  .title {
    font-size: 56px !important;
    line-height: 56px !important;
  }
  .subtitle {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .text {
    font-size: 24px !important;
    line-height: 40px !important;
  }

  .box-text {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

@screen lg {
  .container-padding {
    padding: 2rem 4rem;
  }
}

.title {
  font-size: 28px;
  line-height: 28px;
}

.subtitle {
  font-size: 24px;
  line-height: 36px;
}

.text {
  font-size: 20px;
  line-height: 36px;
}

.box-text {
  font-size: 18px;
  line-height: 22px;
}

.landing-container {
  padding: 1.25rem !important;
}

.countdown-timer {
  font-size: 24px;
  line-height: 36px;
}

/* (A) FIXED WRAPPER */
.hwrap {
  overflow: hidden; /* HIDE SCROLL BAR */
  background: #000;
  color: #fff;
}

/* (B) MOVING TICKER WRAPPER */
.hmove {
  display: flex;
}

/* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
.hitem {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}

/* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
/* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
@keyframes tickerh {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(-400%, 0, 0);
  }
}
.hmove {
  animation: tickerh linear 25s infinite;
}
.hmove:hover {
  animation-play-state: paused;
}
</style>
